import React, { useEffect } from 'react';
import {detailLinkFormatter, dateFormatter, listItemFormatter} from "helpers/formatter";
import {RouteNames} from "router/routes-names";
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import OrderStatusBadge from "components/list-items/OrderStatusBadge";
import Broker from "components/list-items/Broker";
import ListItem from "components/list-items/ListItem";
import OrderPaidSum from "components/list-items/OrderPaidSum";

export const OrderColumnsList = (itemActionMenu) => {


  const {orderStatusesGetList, driversGetList, usersGetList} = useActions()
  const { list: orderStatusesList, isLoading: orderStatusesLoading } = useSelector(state => state.orderStatuses);
  const { list: driversList, isLoading: driversLoading } = useSelector(state => state.drivers);
  const { list: usersList, isLoading: usersLoading } = useSelector(state => state.users);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(orderStatusesGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(driversGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(usersGetList, [])

  const statusFormatter = (data, row, rowIndex, formalabelraData) => {
    return (
      <OrderStatusBadge id={data}/>
    );
  };
  const brokerFormatter = (data, row, rowIndex, formalabelraData) => {
    return (
      <>
        <strong>{row.broker_number}</strong> / <Broker id={row.broker_id}/>
        <div className="text-500">
          {row.location_from} - {row.location_to}
          {row.content.map( (element) => { return <div key={element.id}>{element.title}</div> } )}
        </div>

      </>
    );
  };
  const sumFormatter = (data, row, rowIndex, formalabelraData) => {
    return (
      <>
      <strong>{data}</strong> / <ListItem namespace="paymethods" itemId={row.paymethod_id}/>
      {
        row.broker_fee ? (<div className="text-danger">- {row.broker_fee}</div>) : ('')
      }
      </>
    );
  };
  const paidSumFormatter = (data, row, rowIndex, formalabelraData) => {
    return (
      <OrderPaidSum paidSum={data} orderSum={row.order_sum}/>
    );
  };

  const payDateTillFormatter = (data, row, rowIndex, formalabelraData) => {
    return (
      <>
        {dateFormatter(row.pay_date_till)} {row.due_date ? '(' + row.due_date + ')' : '' }
      </>
    );
  };

  const weekFormatter = (data, row, rowIndex, formalabelraData) => {
    let year = row.year.toString();
    if(year.length === 4) {
      year = year.substr(2,2)
    }
    return (
      <>
        {row.week}/{year}
      </>
    );
  };

  return [
  {
    name: 'id',
    label: 'ID',
    sort: true,
    formatter: detailLinkFormatter({detailLinkRoute: RouteNames.ORDERS_EDIT}),
    filterType: 'number',
  },
  {
    name: 'week',
    label: 'Нед.',
    sort: true,
    formatter: weekFormatter,
    filterType: 'number',
  },
  {
    name: 'drop_date',
    label: 'Дата дропа',
    sort: true,
    formatter: dateFormatter,
    filterType: 'date',
  },
  {
    name: 'order_status_id',
    label: 'Статус',
    sort: true,
    formatter: statusFormatter,
    filterType: 'selectMulti',
    options: orderStatusesList,
    isLoading: orderStatusesLoading,
  },
  {
    name: 'driver_id',
    label: 'Водитель',
    sort: true,
    formatter: listItemFormatter("drivers"),
    filterType: 'selectMulti',
    options: driversList,
    isLoading: driversLoading,
  },
  {
    name: 'dispatcher_id',
    label: 'Диспетчер',
    sort: true,
    formatter: listItemFormatter("users"),
    filterType: 'selectMulti',
    options: usersList,
    isLoading: usersLoading,
  },
  {
  // в списке заказов - номер договора + название брокера
    name: 'broker_number',
    label: 'Информация',
    sort: false,
    formatter: brokerFormatter,
    filterType: 'text',
    filterLabel: 'Номер брокера',
    tooltip: "Номер договора/Брокер. Пикап-дроп, авто"
  },
  {
    name: 'order_sum',
    label: 'Сумма',
    sort: true,
    filterType: 'number',
    formatter: sumFormatter,
    tooltip: "Сумма/Способ оплаты. Брокер фи"
  },
  {
    name: 'pay_date_till',
    label: 'Оплата до',
    sort: true,
    formatter: payDateTillFormatter,
    filterType: 'date',
  },
  {
    name: 'paid_sum',
    label: 'Оплачено',
    sort: true,
    formatter: paidSumFormatter,
    filterType: 'selectMulti',
    options: [
      { id: 'not_paid', title: 'Не оплачено' },
      { id: 'part_paid', title: 'Частично' },
      { id: 'paid', title: 'Полностью' },
      { id: 'paid_over', title: 'Переплата' },
    ],
    isLoading: driversLoading,
  },
  // {
  //   name: '',
  //   label: '',
  //   formatter: actionFormatter,
  //   align: 'right',
  //   formalabelraData: {
  //     menu: itemActionMenu
  //   }
  // }
]};

export default OrderColumnsList;