import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Logo from 'components/navbar/Logo';
import Section from 'components/common/Section';
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';
import LoginForm from './components/LoginForm';

const AuthBasicLayout = () => {

  const {isLoading} = useSelector(state => state.auth);


  return (

    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">

          {isLoading ? (
                      <Loader />
                    ) : (
            <>
              <Logo />
              <Card>
                <CardBody className="fs--1 font-weight-normal p-5">
                  <Fragment>
                    <Row className="text-left justify-content-between">
                      <Col xs="auto">
                        <h5  className='mb-3'>Введите ваш email и пароль</h5>
                      </Col>
                    </Row>
                    <LoginForm />
                  </Fragment>
                </CardBody>
              </Card>
            </>
            )
          }
        </Col>
      </Row>
    </Section>
  );

};

export default AuthBasicLayout;
