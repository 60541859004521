import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormInput from 'components/input/FormInput';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from 'classnames';

const TableEditorFilter = ({
  onFilter,
  column,
  columnData,
  type,
  initialFilterValues={},
  filterLabel,
  resetFilters,
  setResetFilters,
}) => {
  const label = filterLabel || column.text;
  // при необходимости добавить валидацию поля фильтра
  let ValidSchema = yup.object().shape({});
//   if(type === 'date') {
//     ValidSchema[column.dataField] = yup.date().typeError("Неверная дата");
//   }

  const initialValue = initialFilterValues[column.dataField];
  const [filterValue, setFilterValue] = useState(initialValue?.value);
  const [filterValueFrom, setFilterValueFrom] = useState(initialValue?.from);
  const [filterValueTo, setFilterValueTo] = useState(initialValue?.to);
  const [isRange, setIsRange] = useState(filterValueTo);

  const hasRange = (type === 'number' || type === 'date')

  const formMethods = useForm({resolver: yupResolver(ValidSchema) });
  const {handleSubmit} = formMethods;
  // const {errors} = formMethods.formState;

//   const watchAllFields = watch();
//   console.log(watchAllFields);
   // console.log(errors);

  // select применяем сразу при выборе
  useEffect(() => {
      if(type === 'select' || type === 'selectMulti') {
        // значение undefined происходит при загрузке, когда еще ничего не выбрали
        if(filterValue !== undefined && filterValue !== initialValue?.value) {
          applyFilter();
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValue)]);

  // сброс всех фильтров
  useEffect(() => {
    if(resetFilters) {
      setFilterValue(undefined);
      setFilterValueFrom(undefined);
      setFilterValueTo(undefined);
      setIsRange(false);
      onFilter({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  // изменения значения фильтров из вне
  useEffect(() => {
    if(initialValue?.value) {
      setFilterValue(initialValue.value);
    }
  }, [initialValue?.value]);
  useEffect(() => {
    if(initialValue?.from) {
      setFilterValueFrom(initialValue.from);
    }
  }, [initialValue?.from]);
  useEffect(() => {
    if(initialValue?.to) {
      setFilterValueTo(initialValue.to);
    }
  }, [initialValue?.to]);


  const handleInputChange = ({ value, name }) => {
      console.log({name, value});
      setFilterValue(value);
      setFilterValueFrom(value);
  };

  const handleInputChangeFrom = ({ value, name }) => {
      console.log({name, value});
      setFilterValue(value);
      setFilterValueFrom(value);
  };

  const handleInputChangeTo = ({ value, name }) => {
      console.log({name, value});
      setFilterValueTo(value);
  };

  const applyFilter = (formData) => {
    if(isRange) {
      onFilter({type: type, from: filterValueFrom, to: filterValueTo});
    } else {
      onFilter({type: type, value: filterValue});
    }
  }

  const toggleRange = () => {
    if(isRange) {
      setIsRange(false);
    } else {
      setIsRange(true);
    }
  }

  const onKeyDown = (event) => {
    console.log('keypress');
      // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        applyFilter();
      }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(applyFilter)}>
      <Row className="flex-nowrap mx-0">
        {isRange ?
            (
              <Col className={classNames("col-auto px-0", {'filter-range': hasRange})}>
                  <FormInput
                    handleChange={handleInputChangeFrom}
                    id={column.dataField+'From'}
                    name={column.dataField+'From'}
                    placeholder={label + ' от'}
                    className="fs--1"
                    value={filterValueFrom}
                    type={type}
                    options={columnData.options}
                    isLoading={columnData.isLoading}
                    onKeyDown={onKeyDown}
                   />
                   <FormInput
                    handleChange={handleInputChangeTo}
                    name={column.dataField+'To'}
                    placeholder={label + ' до'}
                    className="fs--1"
                    value={filterValueTo}
                    type={type}
                    options={columnData.options}
                    isLoading={columnData.isLoading}
                    onKeyDown={onKeyDown}
                   />
              </Col>
            )    :   (
                <Col className={classNames("col-auto px-0", {'filter-range': hasRange})}>
                  <FormInput
                    handleChange={handleInputChange}
                    id={column.dataField}
                    name={column.dataField}
                    placeholder={label}
                    className="fs--1"
                    value={filterValue}
                    type={type}
                    options={columnData.options}
                    isLoading={columnData.isLoading}
                   />
              </Col>
            )
        }
         { hasRange &&
            <Col className="col-auto pr-0 pl-2">
              <Button color="link" size="sm" type="button" onClick={toggleRange} id="toggleRange" style={{padding: ".1875rem 0"}}>
                  <FontAwesomeIcon icon="arrows-up-down" className="text-primary" transform="shrink-3" />
              </Button>
            </Col>
          }
        </Row>
      </form>
    </FormProvider>
  )
};

export default TableEditorFilter;