import {useMemo} from 'react';
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import {allActionCreators} from "store/reducers/action-creators";
import {actions as authActions} from 'store/reducers/auth'
import {actions} from 'store/reducers'


// Задача получить из всех редюсеров все action creators, как обычные, так и async, и преобразовать из имена к уникальному виду
// Из slice у каждой сущности мы имеем одинаковые имена actions, так как они создаются по одному шаблону
// например, в каждом есть метод setIsLoading, а нам нужно иметь уникальные названия, например, userSetIsLoading, authSetIsLoading и тп

const all = [
    ...Object.entries(allActionCreators),
    ...Object.entries(authActions),
];

Object.keys(actions).forEach( (actionsKey) => { all.push( ...Object.entries(actions[actionsKey]) ) } )
const allNamed = {};

all.forEach(( item ) => {
  const [name, value] = item;
  // если это action из slice, то он имеет свойство type, где записано значение вида user/setIsLoading
  if(value.type) {
      const type = value.type;
      // заменяем / + буква на букву в верхнем регистре (user/setIsLoading -> userSetIsLoading)
      const newKey = type.replace(/\/(\w)/, (match, p1) => p1.toUpperCase() )
      allNamed[newKey] = value
  // если это action из createAsyncThunk, то он имеет свойство typePrefix, где записано значение вида user/login
  } else if(value.typePrefix) {
      const type = value.typePrefix;
      // заменяем / + буква на букву в верхнем регистре (user/login -> userLogin)
      const newKey = type.replace(/\/(\w)/, (match, p1) => p1.toUpperCase() )
      allNamed[newKey] = value
  // если это обычный action creator, не из slice, то отставляем как есть
  } else {
      allNamed[name] = value
  }
});

console.log(allNamed);



export const useActions = () => {
    const dispatch = useDispatch();
    // console.log('useActionsbBind');
    return useMemo( () => bindActionCreators(allNamed, dispatch), [dispatch]);
}

export const useObjectActions = (namespace) => {
    let actionsToBind = {};
    if(namespace) {
      actionsToBind = actions[namespace] || {};
    }
    const dispatch = useDispatch();
    // console.log('useActionsbBind');
    return bindActionCreators(actionsToBind, dispatch);
}
