import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useOrderStatuses} from "hooks/useOrderStatuses";

import {RouteNames} from "router/routes-names";
import TableEditor from "components/editor/TableEditor";
import {OrderColumnsList} from "./components/OrderColumnsList";
import OrderListFilters from "./components/OrderListFilters";
import ImportModal from "./components/ImportModal";
import {useOrders} from "hooks/useOrders";

const Orders = () => {
  const namespace="orders";
  const router = useHistory()
  const {deleteOrder} = useOrders();
  const {getDeletedStatus} = useOrderStatuses();

  const [isOpenImportModal, setIsOpenImportModal] = useState(false);


  const withSelectedOptions = [
      {value: "Delete", title: "Удалить", callback: deleteOrder }
  ];
  const actionButtons = [
      {icon: "plus", title: "Добавить", link: RouteNames.ORDERS_ADD},
      {icon: "file-excel", title: "Импорт", onClick: () => setIsOpenImportModal(true) },
  ];

  const itemActionsMenu = (row) => {
    let actions = [
          { title: "Редактировать", callback: (row) => router.push(RouteNames.ORDERS_EDIT(row.id)) },
      ];
    if(row.order_status_id !== getDeletedStatus().id) {
      actions.push(
          { title: "Удалить", callback: (row) => deleteOrder([row.id]), className: "text-danger" },
      );
    }
    return actions;
  }

  const onRowClick = (e, row, rowIndex) => router.push(RouteNames.ORDERS_EDIT(row.id));

  const allColumnsList = OrderColumnsList(itemActionsMenu);

  return (
    <>
      <TableEditor
        title="Заказы"
        columns={allColumnsList}
        withSelectedOptions={withSelectedOptions}
        actionButtons={actionButtons}
        extraTableFilters={<OrderListFilters/>}
        namespace={namespace}
        onRowClick={onRowClick}
      />


      <ImportModal
        isOpenModal={isOpenImportModal}
        setIsOpenModal={setIsOpenImportModal}
      />

    </>
  );

};

export default Orders;
