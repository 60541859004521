import React, { createContext, useState } from "react";

export const ConfirmContext = createContext();

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    header: "",
    message: "",
    isOpen: false,
    cancelText: 'Отменить',
    confirmText: 'Подтвердить',
    onConfirm: null
  });

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
