import $api from "../http";

export default class AuthService {
    static async login(email, password) {
        return $api.post('/login', {email, password})
    }
    static async loginAs(userId) {
        console.log(userId)
        return $api.post('/loginAs', {user_id: userId})
    }
    static async register(item) {
        return $api.post('/register', {item})
    }
    static async logout() {
        return $api.post('/logout')
    }
    static async logoutToParent() {
        return $api.post('/logoutToParent')
    }

}
