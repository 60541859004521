import {useEffect} from 'react';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import * as yup from "yup";

export const OrderColumnsDetails = (itemActionMenu) => {

  const {orderStatusesGetList, driversGetList, brokersGetList, paymethodsGetList, paytypesGetList, usersGetList} = useActions()
  const { list: orderStatusesList, isLoading: orderStatusesLoading } = useSelector(state => state.orderStatuses);
  const { list: driversList, isLoading: driversLoading } = useSelector(state => state.drivers);
  const { list: brokersList, isLoading: brokersLoading } = useSelector(state => state.brokers);
  const { list: paymethodsList, isLoading: paymethodsLoading } = useSelector(state => state.paymethods);
  const { list: paytypesList, isLoading: paytypesLoading } = useSelector(state => state.paytypes);
  const { list: usersList, isLoading: usersLoading } = useSelector(state => state.users);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(orderStatusesGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(driversGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(brokersGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(paytypesGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(usersGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(paymethodsGetList, [])

  return [
  {
    name: 'id',
    label: 'ID',
    type: 'number',
    placeholder: 'Оставьте пустым, и присвоится автоматически'
  },
  {
    name: 'drop_date',
    label: 'Дата дропа',
    type: 'dateCalendar',
  },
  {
    name: 'order_status_id',
    label: 'Статус',
    type: 'select',
    options: orderStatusesList,
    isLoading: orderStatusesLoading,
    validation: yup.string().required('Выберите статус заказа'),
  },
  {
    name: 'dispatcher_id',
    label: 'Диспетчер',
    type: 'select',
    options: usersList,
    isLoading: usersLoading,
    validation: yup.string().required('Выберите диспетчера'),
  },
  {
    name: 'driver_id',
    label: 'Водитель',
    type: 'select',
    options: driversList,
    isLoading: driversLoading,
  },
  {
    name: 'broker_number',
    label: 'Номер заказа у брокера',
    type: 'text',
  },
  {
    name: 'broker_id',
    label: 'Брокер',
    placeholder: 'Введите название или выберите из списка',
    type: 'selectCreatable',
    namespace: 'brokers',
    options: brokersList,
    isLoading: brokersLoading,
  },
  {
    name: 'location_from',
    label: 'Пикап',
    type: 'text',
  },
  {
    name: 'location_to',
    label: 'Дроп',
    type: 'text',
  },
  {
    name: 'distance',
    label: 'Расстояние, км',
    type: 'number',
  },
  {
    name: 'paymethod_id',
    label: 'Тип оплаты',
    type: 'select',
    options: paymethodsList,
    isLoading: paymethodsLoading,
  },
  {
    name: 'paytype_id',
    label: 'Момент расчетов',
    // validation: yup.string().required('Выберите момент расчетов'),
    type: 'select',
    options: paytypesList,
    isLoading: paytypesLoading,
  },
  {
    name: 'broker_fee',
    label: 'Брокер фи',
    type: 'number',
  },
  {
    name: 'order_sum',
    label: 'Сумма',
    type: 'number',
  },
  {
    name: 'is_paid',
    label: 'Заказ оплачен',
    type: 'checkbox',
  },
  {
    name: 'is_fee_paid',
    label: 'Вознаграждение брокеру оплачено',
    type: 'checkbox',
  },
  {
    name: 'due_date',
    label: 'Количество рабочих дней на оплату',
    type: 'number',
  },
  {
    name: 'pay_date_till',
    label: 'Оплата до',
    type: 'dateCalendar',
    tooltip: 'Рассчитывается количество дней на оплату от даты дропа с учетом праздников и выходных'
  },
  {
    name: 'paid_sum',
    label: 'Оплачено',
    type: 'number',
  },
]};

export default OrderColumnsDetails;