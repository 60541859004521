import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FormInput from 'components/input/FormInput';
import {useEditFormContext} from 'hooks/useEditFormContext';

export const VehicleDetailsFields = () => {

  const {getField, item: vehicle} = useEditFormContext();

  const vehicleId = vehicle.id;
  const isEditVehicle = vehicle.id;

  return (
    <Card className="mb-3">
      <FalconCardHeader title={isEditVehicle ? 'Редактировать транспортное средство '+vehicleId : 'Добавить транспортное средство'} light={false}/>

      <CardBody className="bvehicle-top bg-light">
        <Row>
          <Col lg={4}>
            {getField('title')}
          </Col>
          <Col lg={4}>
            {getField('vin')}
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            {getField('vehicle_type_id')}
          </Col>
          <Col lg={4}>
            {getField('model')}
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            {getField('cost')}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default VehicleDetailsFields;
