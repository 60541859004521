import React, { Fragment, useEffect } from 'react';
import {VehicleDetails} from "./components/VehicleDetails";
import {useParams} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';

const VehiclesEdit = () => {
  const params = useParams()
  const {vehiclesGetItem} = useActions()
  const {isGetItemLoading, item} = useSelector(state => state.vehicles);

  useEffect(() => {
      vehiclesGetItem(params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
     <Fragment>
          { (isGetItemLoading || !item.id )? ( <Loader/> ) : ( <VehicleDetails vehicleItem={item}/> ) }
     </Fragment>
  );
};

export default VehiclesEdit;
