import {detailLinkFormatter, actionFormatter, dateFormatter} from "helpers/formatter";
import {RouteNames} from "router/routes-names";


export const DriverColumns = (itemActionMenu) => {
  // const {trucksGetList} = useActions()
  // const { list: trucksList, isLoading: trucksLoading } = useSelector(state => state.trucks);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(trucksGetList, [])


  return [
  {
    name: 'id',
    label: 'ID',
    formatter: detailLinkFormatter({detailLinkRoute: RouteNames.DRIVERS_EDIT}),
  },
  {
    name: 'title',
    label: 'Имя',
    formatter: detailLinkFormatter({detailLinkRoute: RouteNames.DRIVERS_EDIT}),
  },
  {
    name: 'phone',
    label: 'Номер телефона',
  },
  {
    name: 'reg_date',
    label: 'Дата регистрации',
    align: 'right',
    headerAlign: 'right',
    formatter: dateFormatter
  },
  {
    name: '',
    label: '',
    formatter: actionFormatter,
    align: 'right',
    formatExtraData: {
      menu: itemActionMenu
    }
  }
]};

export default DriverColumns;