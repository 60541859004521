import React, {useEffect, useState} from 'react';
import {Form} from 'reactstrap';
import CardFooterSave from 'components/editor/CardFooterSave';
import {useActions} from "hooks/useActions";
import {useListItem} from "hooks/useListItem";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {RouteNames} from "router/routes-names";
import DriverFormFields from './DriverFormFields';
import {useTrucks} from "hooks/useTrucks";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, FormProvider} from 'react-hook-form';


export const DriverProfile = ({ driverItem={} }) => {
  const {error, isAdded, isUpdated} = useSelector(state => state.drivers);
  const {driversAdd, driversEdit, driversSetIsAdded, driversSetIsUpdated, trucksSetIsListDirty} = useActions();
  const {getItem: getTruck} = useListItem('trucks');
  const driversRouteName = 'DRIVERS';
  const {getTruckByVin} = useTrucks();

  const [driver, setDriver] = useState(driverItem);
  const [truck, setTruck] = useState( driver.is_owner ? getTruck(driver.truck_id) : {});

  let ValidSchema = yup.object().shape({
    title: yup.string().required('Введите имя'),
    email: yup.string().email('Email введен неверно'),
    // vin: yup.string().concat( driver.is_owner ?  yup.string().required('Введите VIN грузовика') : null ),
    vin: yup.string().when('is_owner', ([is_owner], schema) => {
      return is_owner === "0" ? schema : schema.required('Введите VIN грузовика')}),
    company_id: yup.number().required('Необходимо выбрать компанию'),
    // truck_id: yup.string().concat( driver.is_owner ? null : yup.string().required('Выберите грузовик') ),
    truck_id: yup.string().when('is_owner', ([is_owner], schema) => {
      return is_owner !== "0" ? schema : schema.required('Выберите грузовик');
    }),
  });
  const formMethods = useForm({resolver: yupResolver(ValidSchema) });
  const {handleSubmit} = formMethods;


  const driverId = driverItem.id;
  const isEditProfile = driverItem.id;
  const isAddProfile = !driverItem.id;

  const router = useHistory();

  const handleDriverChange = ({ value, name }) => {
      // console.log({name, value});
      setDriver({ ...driver, [name]: value })
  };
  const handleTruckChange = ({ value, name }) => {
      // console.log({name, value});
      setTruck({ ...truck, [name]: value })
  };

  const onSubmit = async (data, event) => {
    const sendData = {...driver, truck: truck };
    delete sendData.reg_date;

    // Если добавляемый грузовик с этим VIN уже есть, то указываем его id, чтобы на беке была попытка добавить грузовик к водителю, а не добавлять новый
    if(truck.vin && !truck.id) {
      const foundTruck = getTruckByVin({ vin: truck.vin});
      if(foundTruck.id) {
        sendData.truck = { ...sendData.truck, "id": foundTruck.id };
      }
    }

    console.log(sendData);

    if(isEditProfile) {
      driversEdit([driverId, sendData]);
    } else {
      driversAdd(sendData)
    }
    // в процессе редактирования водителя может меняться список грузовиков, поэтому обновляем его
    trucksSetIsListDirty(true);
  };

  useEffect(() => {
    if(isAdded || isUpdated) {
      driversSetIsAdded(false);
      driversSetIsUpdated(false);
      router.push(RouteNames[driversRouteName]);
    }
    // eslint-disable-next-line
  }, [isAdded, isUpdated]);


  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DriverFormFields
          handleDriverChange={handleDriverChange}
          driver={driver}
        />
        <CardFooterSave
          error={error}
          isAdd={isAddProfile}
          cancelRouteName={driversRouteName}
        />
      </Form>
    </FormProvider>
  );
};

export default DriverProfile;
