import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {useEditFormContext} from 'hooks/useEditFormContext';

export const OrderDetailsPay = () => {

  const {getField, getDisabledField} = useEditFormContext();

  return (
    <Card className="mb-3 mb-lg-0">
      <FalconCardHeader title="Оплата заказа" light={false} />
      <CardBody className="bg-light">
        <Row>
          <Col lg={9}>
            {getField('paytype_id')}
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
            {getField('paymethod_id')}
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
            {getField('due_date')}
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
            {getDisabledField('pay_date_till')}
          </Col>
        </Row>
        {/* <Row> */}
        {/*   <Col lg={9} style={{display: "flex", alignItems: "center"}} className="pl-0 pt-2"> */}
        {/*     {getField('is_paid')} */}
        {/*   </Col> */}
        {/* </Row> */}
        {/* <Row> */}
        {/*   <Col lg={9} style={{display: "flex", alignItems: "center"}} className="pl-0 pt-2"> */}
        {/*     {getField('is_fee_paid')} */}
        {/*   </Col> */}
        {/* </Row> */}
      </CardBody>
    </Card>
  );
};

export default OrderDetailsPay;
