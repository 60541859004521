import React, { useEffect} from 'react';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import orderBy from 'lodash/orderBy';
import {isIterableArray} from 'helpers/utils';
import Loader from 'components/common/Loader';
import ParamsRow from './components/ParamsRow';
import Error from 'components/common/Error';


const Params = () => {

  const {paramsGetList, paramsEdit } = useActions()
  const {list, isLoading, error} = useSelector(state => state['params']);
  
  useEffect(() => {
    paramsGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editorHeader = "Настройки";
  
  const colsInputs = [
    {
      header: "Название",
      type: "text",
      name: "title",
      disabledEdit: true,
    },
    {
      header: "Значение",
      name: "value",
      colProps: {"align": "left"},
      classes: 'align-top',
    },
  ];
   
  const sort = (list) => orderBy(list, ['sort_weight']);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={editorHeader} light={false} />
        <CardBody className="bg-light">
        <Error message={error}/>
        <Container className="bg-white">
          <Row className="fs--1 px-1 pt-3 pb-1 font-weight-semi-bold">
            {colsInputs.map(column =>
              <Col {...column.colProps} className="py-1" key={column.name}>{column.header}</Col>
            )}
            <Col md={2}></Col>
          </Row>
        {isLoading ? (
          <Row>
            <Col><Loader /></Col>
          </Row>
        ) : (
          isIterableArray(list) &&
          sort(list).map(param => (
            <ParamsRow
              item={param}
              key={param.name}
              handleEdit={paramsEdit}
              disableBth={isLoading}
              colsInputs={colsInputs}
            />
          ))
        )}
      </Container>
      <div style={{display: 'flex'}} className="pt-3">

      </div>
      </CardBody>

    </Card>
  );


};

export default Params;