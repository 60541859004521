import React, {useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useForm, FormProvider} from "react-hook-form";
import FormInput from 'components/input/FormInput';

const ParamsRow = ({
  item,
  handleEdit,
  disableBth,
  colsInputs
}) => {
  const [row, setRow] = useState(item);

  const handleInputChange = ({ value, name }) => {
    setRow({ ...row, [name]: value })
  };


  const formMethods = useForm({ mode: 'onSubmit' });
  const {handleSubmit} = formMethods;
  const {isDirty} = formMethods.formState;

  const [isEditing, setIsEditing] = useState(false);
  
  const startEdit = (e) => {
    setIsEditing(true);
    e.preventDefault();
  }

  const onSubmit = (formData) => {
    if(isDirty) {
      handleEdit([row.id, row]);
    }
    setIsEditing(false);
  }

  const cancel = (e) => {
    setIsEditing(false);
  }


  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="fs--1 font-weight-semi-bold">
          {colsInputs.map((column, index )=>
            <Col key={index} {...column.colProps} className={column.classes}>
              <FormInput
                bsSize="sm"
                placeholder={column.header}
                type={column.type || row.type}
                name={column.name}
                id={column.name}
                value={row[column.name]}
                // Можем редактировать поле если disabledEdit=false
                disabled={column.disabledEdit || !isEditing}
                handleChange={handleInputChange}
                className={ (!column.type && row.type === 'checkbox') && 'mt-0'}
              />
          </Col>
          )}

          { isEditing ? (
            <Col md={2}>
              <Button color="link" size="sm" type="submit" disabled={disableBth}>
                <FontAwesomeIcon icon="circle-check" transform="shrink-3" />
              </Button>
              <Button color="link" size="sm" type="button" onClick={cancel} disabled={disableBth}>
                <FontAwesomeIcon icon="xmark" className="text-danger" transform="shrink-3" />
              </Button>
            </Col>
          ) : (
            <Col md={2}>
              <Button color="link" size="sm" type="button" onClick={startEdit} disabled={disableBth}>
                <FontAwesomeIcon icon="pen-to-square" transform="shrink-3" />
              </Button>
            </Col>
          )}
        </Row>
      </form>
    </FormProvider>
  )
};

export default ParamsRow;