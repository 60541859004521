import {createSlice} from "@reduxjs/toolkit";
import {baseObjectInitial, baseObjectReducers, baseObjectExtraReducers} from "../shared/reducerBaseObject";
import {baseFiltersInitial, baseFiltersReducers } from "../shared/reducerBaseFilters";
import {baseObjectEditorInitial, baseObjectEditorReducers, BaseObjectEditorActionCreators, baseObjectEditorExtraReducers} from "../shared/reducerBaseObjectEditor";

export default class reducerObjectEditor {
    constructor(namespace, service, options = {}) {
        this.namespace = namespace;
        this.service = service;

        let initialState = {
          ...baseObjectInitial,
          ...baseObjectEditorInitial,
        };

        let reducers = {
          ...baseObjectReducers,
          ...baseObjectEditorReducers
        };

        if(options.useFilters) {
          initialState = { ...initialState, ...baseFiltersInitial };
          reducers = { ...reducers, ...baseFiltersReducers };
        }

        if(options.initialState) {
          initialState = { ...initialState, ...options.initialState };
        }

        this.actionCreators = BaseObjectEditorActionCreators(this.namespace, this.service);

        this.slice = createSlice({
          name: namespace,
          initialState,
          reducers: reducers,
          extraReducers: (builder) => {
              baseObjectEditorExtraReducers(builder, this.actionCreators);
              baseObjectExtraReducers(builder, this.namespace);
          }
        });

     }
    reducer() {
        return this.slice.reducer
    }
    actions() {
        return {
          ...this.slice.actions,
          ...this.actionCreators
        };
    }
}
