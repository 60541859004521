import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { reducers } from './reducers'

const combinedReducer = combineReducers(reducers);


const rootReducer = (state, action) => {
  // При логауте и входе очищаем хранилище
  if (  action.type === 'auth/logout/fulfilled'
      || action.type === 'auth/login/fulfilled'
      || action.type === 'auth/loginAs/fulfilled'
      || action.type === 'auth/logoutToParent/fulfilled'
  ) {
    console.log('state undefined');
    state = undefined;
  }

  return combinedReducer(state, action);
};


export const store = configureStore({
  reducer: rootReducer
})

export default store