import React, { useEffect } from "react";
import useConfirm from "hooks/useConfirm";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const ConfirmAlert = () => {
    const { header = "", message = "", isOpen = false, cancelText, confirmText,  proceed, cancel } = useConfirm();

    // const [isOpen, setIsOpen] = useState(false);
    // const toggleModal = () => setIsOpen(!isOpen);

    useEffect(() => {
      const handleKeydown = (e) => {
        if (proceed && isOpen && e.key === "Enter") {
          proceed();
        }
      };

      window.addEventListener("keydown", handleKeydown);
      return () => window.removeEventListener("keydown", handleKeydown);
    }, [proceed, isOpen]);


    return (
          <Modal isOpen={isOpen} toggle={cancel} centered>
              {header && <ModalHeader>{header}</ModalHeader> }
              <ModalBody>
                  <div className="fs--1">
                      {message}
                  </div>
              </ModalBody>
              <ModalFooter>
                  <Button color="secondary" size="sm" onClick={cancel}>
                      {cancelText}
                  </Button>
                  <Button color="primary" size="sm" onClick={proceed}>
                      {confirmText}
                  </Button>
              </ModalFooter>
          </Modal>
    );
};
export default ConfirmAlert;
