import React, { Fragment } from 'react';
import {DriverProfile} from "./components/DriverProfile";

const DriversAdd = () => {

  return (
     <Fragment>
       <DriverProfile />
     </Fragment>
  );
};

export default DriversAdd;
