import React from 'react';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CashflowSum = ({sum, flowType}) => {
  let color = '';
  let icon = '';

  if(flowType === "inflow") {
    color = 'success';
    icon = 'plus';
  } else {
    color = 'soft-danger';
    icon = 'minus';
  }

  return (
    <Badge color={color} className="fs--1 rounded-capsule">
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="fs--1 mr-1" />
      {sum}
    </Badge>
  );
};

export default CashflowSum;
