import React, {useState} from 'react';
import {Button, Col, Row, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useForm, FormProvider} from 'react-hook-form';
import FormInput from 'components/input/FormInput';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

const TableEditorCellEdit = ({
  item,
  name,
  editorProps,
  defaultValue,
  customData,
  validation,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const ValidSchema = yup.object().shape({
    [name]: validation
  });

  const formMethods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit', resolver: yupResolver(ValidSchema)});
  const {handleSubmit} = formMethods;
  const {isDirty} = formMethods.formState;

  const handleInputChange = ({ value, name }) => {
      console.log({name, value});
      setValue(value);
  };

  const save = (formData) => {
    if(isDirty) {
      editorProps.onUpdate(value);
    } else {
      editorProps.onUpdate("saveOldValue");
    }
  }

  const cancel = (e) => {
    // если не обновить значение, то редактируемое поле не убирается
    // Делаем искусственное обновление на старое значение, дальше при обработке изменений отлавливаем значение saveOldValue
    editorProps.onUpdate("saveOldValue");
    e.preventDefault();
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(save)}>
        <Row className="flex-nowrap">
          <Col className="col-auto px-0">
            <Button color="link" size="sm" type="submit" id="confirmEdit" style={{padding: ".1875rem 0.5rem .1875rem 0"}}>
              <FontAwesomeIcon icon="circle-check" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="confirmEdit">Сохранить изменения</UncontrolledTooltip>

            <Button color="link" size="sm" type="button" onClick={cancel} id="cancel" style={{padding: ".1875rem 0.5rem .1875rem 0"}}>
              <FontAwesomeIcon icon="xmark" className="text-danger" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="cancel">Отменить</UncontrolledTooltip>
          </Col>
          <Col className="col-auto  px-0">
            <FormInput
              bsSize="sm"
              handleChange={handleInputChange}
              value={defaultValue}
              customData={ {...customData, row: item} }
              name={name}
              {...rest}
            />
          </Col>        </Row>
      </form>
    </FormProvider>
  )
};

export default TableEditorCellEdit;