import {useMemo} from 'react';
import {useSelector} from "react-redux";

export const UserACL = ({role=[], children}) => {

  const currentUser = useSelector(state => state.auth.item);
  let userRole = currentUser?.access_role;
  if (typeof userRole === 'string') {
    userRole = Array(userRole)
  }
  const hasRole = useMemo( () => userRole?.some(v => role.includes(v)), [role, userRole]);

  if(!role.length || hasRole) {
    return children;
  }
  else {
    return null;
  }

};

export default UserACL;
