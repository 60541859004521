import React from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {RouteNames} from "router/routes-names";
import {Link} from 'react-router-dom';
import Error from 'components/common/Error';
import {useFormContext} from 'react-hook-form';

// Если задан setStayOnPage, то показывается дополнительная кнопка Сохранить и остаться на странице
const CardFooterSave = ({ error, isAdd, cancelRouteName, onClick, setStayOnPage }) => {
  const formMethods = useFormContext();
  const {isDirty} = formMethods.formState;

  const onClickStayOnPage = () => {
    setStayOnPage(true);
    onClick && onClick();
  }

  return (
     <Card className="mb-3 sticky-bottom">
        <CardBody className="bg-light">
          <Row>
            <Col lg={12}>
              <Error message={error}/>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col md>
              {isDirty && <span className="mb-2 mb-md-0">Сохранить изменения</span> }
            </Col>
            <Col xs="auto">
            {setStayOnPage && <Button
                         color="falcon-primary"
                         type="submit"
                         disabled={!isDirty}
                         onClick={onClickStayOnPage}
                       >
                         {isAdd ? 'Добавить и остаться' : 'Изменить и остаться'}
                       </Button> }
              <Button
                color="primary"
                type="submit"
                disabled={!isDirty}
                onClick={onClick}
                className="ml-2"
              >
                {isAdd ? 'Добавить и вернуться' : 'Изменить и вернуться'}
              </Button>
              <Button color="secondary" tag={Link} to={RouteNames[cancelRouteName]} className="ml-2">
                Вернуться  {isDirty && "без сохранения" }
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
}

export default CardFooterSave;
