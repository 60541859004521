import React, { useEffect } from 'react';
import {DriverProfile} from "./components/DriverProfile";
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';
import {useParams} from 'react-router-dom';


const DriversEdit = () => {
  const params = useParams()
  const {driversGetItem} = useActions();
  const {isGetItemLoading, item} = useSelector(state => state.drivers);

  useEffect(() => {
      driversGetItem(params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isGetItemLoading ? <Loader/> : <DriverProfile driverItem={item}/>;
};


export default DriversEdit;
