import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {RouteNames} from "router/routes-names";
import PropTypes from 'prop-types';
import FormInput from 'components/input/FormInput';
import CardFooterSave from 'components/editor/CardFooterSave';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm, FormProvider} from 'react-hook-form';
import {EditFormProvider} from 'components/editor/EditFormProvider';
import {VehicleColumns} from "./VehicleColumns";
import {VehicleDetailsFields} from "./VehicleDetailsFields";
import VehicleOwners from './VehicleOwners';

export const VehicleDetails = ({vehicleItem = {}}) => {

  const router = useHistory();
  const {error, isAdded, isUpdated} = useSelector(state => state.vehicles);
  const {vehiclesAdd, vehiclesEdit, vehiclesSetIsUpdated, vehiclesSetIsAdded} = useActions();
  const vehicleId = vehicleItem.id;
  const isEdit = vehicleId;
  const isAdd = !vehicleId;
  const namespace = 'VEHICLES';
  const initialData = isEdit ? vehicleItem : {};
  const [vehicle, setVehicle] = useState(initialData);
  const [stayOnPage, setStayOnPage] = useState();

  const onSubmit = (data, event) => {
    const sendData = {...vehicle };
    console.log(sendData);
    if(isEdit) {
      vehiclesEdit([vehicleId, sendData]);
    } else {
      vehiclesAdd(sendData);
    }
  };


  useEffect(() => {
    if(isUpdated) {
      vehiclesSetIsUpdated(false);
      router.push(RouteNames[namespace])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);


  useEffect(() => {
    if(isAdded || isUpdated) {
      vehiclesSetIsAdded(false);
      vehiclesSetIsUpdated(false);
      setVehicle(vehicleItem);
      if(stayOnPage) {
        setStayOnPage(false);
      } else {
        router.push(RouteNames.VEHICLES)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  const columnsList = VehicleColumns();


  return (
    <EditFormProvider
      fields={columnsList}
      onSubmit={onSubmit}
      item={vehicle}
      setItem={setVehicle}
    >

        <VehicleDetailsFields/>

        <VehicleOwners vehicleId={vehicleId} content={vehicle.owners}/>

        <CardFooterSave
          isAdd={isAdd}
          setStayOnPage={setStayOnPage}
          cancelRouteName={namespace}
        />
    </EditFormProvider>


  );
};


export default VehicleDetails;
