import React from 'react';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrderPaidSum = ({paidSum=0, orderSum=0, payDateTo}) => {
  let color = '';
  let icon = '';

  if(paidSum > 0 && paidSum === orderSum) {
    color = 'success';
    icon = 'check';
  } else if (paidSum > orderSum) {
    color = 'success';
    icon = 'check-double';
  } else if (paidSum < orderSum && paidSum > 0) {
    color = 'soft-warning';
    icon = 'bars-staggered';
  } else {
    color = 'soft-secondary';
    icon = 'ban';
  }

  return (
    <Badge color={color} className="fs--1 rounded-capsule">
      {paidSum}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="fs--1 ml-1" />
    </Badge>
  );
};

export default OrderPaidSum;
