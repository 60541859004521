import React from 'react';
import TableEditor from "components/editor/TableEditor";
import {UserColumns} from "./components/UserColumns";
import {RouteNames} from "router/routes-names";
import {useHistory} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";


const Users = () => {
  const namespace="users";
  const router = useHistory();
  const { usersRemove } = useActions();
  const { item: authItem } = useSelector(state => state.auth);

  const withSelectedOptions = [
    {value: "delete", title: "Удалить", callback: usersRemove },
  ];

  const actionButtons = [
      {icon: "plus", title: "Добавить", link: RouteNames.USERS_ADD}
  ];
  // список действий над выбранными элементами
  const itemActionsMenu = (row) => {
    return authItem.access_role !== 'developer' && row.access_role === 'developer' ? [] : [
      {title: "Редактировать", callback: (row) => router.push(RouteNames.USERS_EDIT(row.id))},
      {title: "Удалить", callback: async (user) => usersRemove([user.id]), className: "text-danger" }
    ]
  };

  const columns = UserColumns({itemActionsMenu});
  return (
    <TableEditor
      title="Пользователи"
      columns={columns}
      withSelectedOptions={withSelectedOptions}
      actionButtons={actionButtons}
      namespace={namespace}
    />
  );
};

export default Users;