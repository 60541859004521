import React, { useEffect, useState } from 'react';
import {useActions} from "hooks/useActions";
import {CashflowColumns} from "./CashflowColumns";
import {Card, CardBody, Col, Button, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {EditFormProvider} from 'components/editor/EditFormProvider';
import {useEditFormContext} from 'hooks/useEditFormContext';
import Error from 'components/common/Error';
import {useSelector} from "react-redux";


const AddForm = () => {

  const {getField, item} = useEditFormContext();
  const {error, isLoading} = useSelector(state => state.cashflow);
  const [isOrderPayment, setIsOrderPayment] = useState(item.cashflow_operation_type_id);

  // определение оплаты по договору
  useEffect(() => {
    // TODO: сделать систему правил для типов, чтобы не сравнивать id - !Повторяется в CashflowColumns
    if(item.cashflow_operation_type_id === "10") {
      setIsOrderPayment(true);
    } else {
      setIsOrderPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.cashflow_operation_type_id]);


  return (
    <>
      <FalconCardHeader title="Добавить транзакцию" light={false} />
      <CardBody className="border-top bg-light">
        <Row>
          <Col lg={5}>
            {getField('flow_type')}
          </Col>
          <Col lg={5}>
            {getField('cashflow_operation_type_id')}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {getField('date')}
          </Col>
          <Col lg={5}>
            {getField('sum')}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {getField('wallet_id')}
          </Col>
          <Col lg={5}>
            {getField('wallet_id_to')}
          </Col>
        </Row>
        {isOrderPayment &&
          <Row>
            <Col lg={5}>
              {getField('order_id')}
            </Col>
            <Col lg={5}>
              {getField('paymethod_id')}
            </Col>
          </Row>
        }
        <Row>
          <Col lg={8}>
            {getField('comment')}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Error message={error}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary" type="submit" disabled={isLoading}>
               Добавить
            </Button>
          </Col>
        </Row>
      </CardBody>
    </>
  )
}

export const CashflowAddForm = ({cashflowItem = {}}) => {
  const allColumnsList = CashflowColumns();
  const [cashflow, setCashflow] = useState(cashflowItem);
  const {cashflowAdd} = useActions();

  const onSubmit = (data, e) => {
    console.log(cashflow);
    cashflowAdd(cashflow);
  };

  return (
    <Card className="mb-3">
      <EditFormProvider
        fields={allColumnsList}
        onSubmit={onSubmit}
        item={cashflow}
        setItem={setCashflow}
      >
         <AddForm/>
       </EditFormProvider>
    </Card>
  );
};

export default CashflowAddForm;