import React, { useEffect } from 'react';
import {Button, CardBody} from 'reactstrap';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";

const OrderListFilters = () => {
  const { list: orderStatuses } = useSelector(state => state.orderStatuses);
  const { filter } = useSelector(state => state.orders);
  const { orderStatusesGetList, ordersSetFilter} = useActions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(orderStatusesGetList, [])

  const changeStatus = (itemIds) => {
    ordersSetFilter({order_status_id: { type: 'select', value: itemIds }} );
    return false;
  }

  return (
      <CardBody className="p-0 ml-3 mb-4">
        {orderStatuses && orderStatuses.map( (item) => (
                    <Button
                    color={item.color}
                    size={filter.order_status_id === item.id ? "lg" : "sm" }
                    active={filter.order_status_id?.value?.includes(item.id) }
                    key={item.id}
                    className="fs--1 mr-2"
                    onClick={() => changeStatus([item.id])}>
                      {item.title}
                    </Button>
            ) )}

      </CardBody>
  );
};

export default OrderListFilters;
