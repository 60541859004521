
export const RouteNames = {
    DASHBOARD: '/dashboard',
    LOGIN: '/login',
    USERS: '/users',
    USERS_ADD: '/users/add',
    USERS_EDIT: (id) => ('/users/'+id),
    PAYMETHODS: '/paymethods',
    ORDERS: '/orders',
    ORDERS_ADD: '/orders/add',
    ORDERS_EDIT: (id) => ('/orders/'+id),
    DRIVERS: '/drivers',
    DRIVERS_ADD: '/drivers/add',
    DRIVERS_EDIT: (id) => ('/drivers/'+id),
    OWNERS: '/owners',
    OWNERS_ADD: '/owners/add',
    OWNERS_EDIT: (id) => ('/owners/'+id),
    VEHICLES: '/vehicles',
    VEHICLES_ADD: '/vehicles/add',
    VEHICLES_EDIT: (id) => ('/vehicles/'+id),
    PAGE_PARAMS: '/params',
    BROKERS: '/brokers',
    CASHFLOW: '/dictionaries/cashflow',
}
export default RouteNames;
