import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import {useSelector} from "react-redux";
import {useActions} from 'hooks/useActions';
import RouteNames from 'router/routes-names';


const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const user = useSelector(state => state.auth.item);
  const {authLogout, authLogoutToParent} = useActions();

  const logoutToParent = () => {
    authLogoutToParent();
  }

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Button color="secondary" outline type="button" id="profile"  size="sm" >
          {user?.title}
        </Button>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="font-weight-bold text-warning"  href={RouteNames.USERS_EDIT(user?.id)}>
            <span>Профиль</span>
          </DropdownItem>
          <DropdownItem divider />
          {
              user?.parent_user_id &&
              <DropdownItem onClick={logoutToParent}>
                <span>Вернуться</span>
              </DropdownItem>
          }

          <DropdownItem onClick={authLogout} tag={Link} to="/logout">
            Выйти
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
