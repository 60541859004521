import React from 'react';
import { useListItem } from "hooks/useListItem";

const Broker = ({id}) => {
  const {getItem} = useListItem('brokers');
  const item = getItem(id);

  return (
    <>
      { item && item.title }
    </>
  );
};

export default Broker;
