import React, {useEffect, useState} from 'react';
import { FormGroup } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputDateCalendar = ({
  label,
  id,
  name,
  value='',
  placeholder,
  tooltip,
  handleChange,
  className,
  ...rest
}) => {

  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    var date = moment(value);
    // ���������� ���������� � ���� ������ DD.MM.YYYY, � � �������� - YYYY-MM-DD
    if(date.isValid()) {
      setCurrentValue(date.format('DD.MM.YYYY'));
      formMethods.setValue(name, date.format('YYYY-MM-DD'));
      handleChange({ name: name, value: date.format('YYYY-MM-DD') });
    } else {
      setCurrentValue('');
      formMethods.setValue(name, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { ref, onChange, onBlur } = formMethods.register(name);
  return (
    <FormGroup>
      <InputLabel id={id} label={label} tooltip={tooltip}/>
      <Datetime
        id={id}
        dateFormat="DD.MM.YYYY"
        timeFormat={false}
        input={true}
        closeOnSelect={true}
        className="fs--1"
        inputProps={{
          name,
          placeholder,
          className: classNames('form-control', { 'border-danger': errors[name]?.message }),
          ...rest
        }}
        innerRef={ref}
        onBlur={onBlur}
        onChange={ (currentDate) => {
          var date = moment(currentDate, "DD.MM.YYYY", true);
  console.log(date);
          setCurrentValue(currentDate);
          if(date.isValid()) {
            // �������, ����� ����������� ������ � react-hook-form, �������� event
            onChange({ target: {name: name, value: date.format('YYYY-MM-DD')}, type: 'change'});
            handleChange({ name: name, value: date.format('YYYY-MM-DD') });
          }
        }}
        value={currentValue}
      />
      { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
    </FormGroup>
  );
}

export default FormInputDateCalendar;
