import {useSelector} from "react-redux";
import {useObjectActions} from "hooks/useActions";

export const useOrderStatuses = () => {
  const {list} = useSelector(state => state.orderStatuses);
  const {getList} = useObjectActions('orderStatuses');
  getList();

  const getDeletedStatus = () => {
    return list.find((item) => item.name === 'deleted')
  }

  const getNamedStatus = (statusName) => {
    return list.find((item) => item.name === statusName)
  }


  return {getDeletedStatus, getNamedStatus};
}