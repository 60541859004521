import React, {useEffect, useState} from 'react';
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputDate = ({
  label,
  id,
  name,
  defaultValue,
  placeholder,
  tooltip,
  handleChange,
  className,
  value='',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    if(value && value.match(/\d/g).length===11) {
      formMethods.setValue(name, value, { shouldDirty: true });
      setCurrentValue(value);
    } else {
      formMethods.setValue(name, '', { shouldDirty: true });
      setCurrentValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  formMethods.register(name);

  return (
    <FormGroup>
       <InputLabel id={id} label={label} tooltip={tooltip}/>
       <InputMask
        name={name}
        id={id}
        mask='+7(999)999-99-99'
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={classNames(className, 'form-control', { 'border-danger': errors[name]?.message })}
          // если первой ввели 8, то убираем ее, потому что скорее всего хотели ввести первую восьмерку, которая уже есть в маске
        beforeMaskedStateChange={ ({ nextState, previousState }) => {
          let { value } = nextState;
          if(value==='+7(8__)___-__-__') {
            value = '+7(___)___-__-__';
            return {
              ...previousState,
              value
            };
          }
          return {...nextState };
        }}
        // onchange должнен быть в конце
        onChange={ (event) => {
          const phone = event.target.value;
          setCurrentValue(phone);
          // когда ввели все 11 цифр
          if(phone && phone.match(/\d/g).length===11) {
            formMethods.clearErrors([name]);
            // чтобы обновлялись данные в react-hook-form
            formMethods.setValue(name, phone, { shouldDirty: true });
            handleChange({ name: name, value: phone });
          }
          // если телефон пустой
          else if(phone === '+7(___)___-__-__') {
            formMethods.clearErrors([name]);
            // чтобы обновлялись данные в react-hook-form
            formMethods.setValue(name, '', { shouldDirty: true });
            handleChange({ name: name, value: '' });
          }
          // если в номере не осталось подчеркиваний, то есть он введена полностью, не соответствует валидному значению
          else if(phone.length === 16 && !phone.includes("_")) {
            formMethods.setError(name, {
              type: "manual",
              message: 'Номер телефона введен неверно',
            });
          }
        }}
        value={currentValue}
        {...rest}
      />
      { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
    </FormGroup>
  );
}

export default FormInputDate;