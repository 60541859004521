import React, { useEffect, useState } from 'react';
import { FormGroup, Spinner } from 'reactstrap';
import classNames from 'classnames';
import Select from "react-select";
import {isIterableArray} from 'helpers/utils';
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputSelectMulti = ({
  label,
  id,
  name,
  placeholder,
  tooltip,
  className,
  options = [], // варианты для select
  isLoading, // признак загрузки списка в случае с select
  handleChange,
  value=[], // значение в виде массива id - [2,3,5]
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;

  const [selectOptions, setSelectOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState();

  useEffect(() => {
    setSelectOptions(options);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);


  // обновление текущего в списке
  useEffect(() => {
    if(value && !isLoading && isIterableArray(selectOptions)) {
      const transformValue = value.map( id => {
                          const found = selectOptions.filter(option => id === option["id"])
                          return { value: found[0]["id"], label: found[0]["title"]}
                  })


      setSelectedOptions(transformValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value), isLoading, isIterableArray(selectOptions), selectOptions.length ]);

  const { ref, onBlur } = formMethods.register(name);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #d8e2ef;',
      minHeight: '1px',
      borderRadius: '.25rem',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 'normal',
      color: '#b6c1d2',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: 'none',
    }),

  }
  return (
    <>
      {  ( isLoading || !isIterableArray(options) ) ?
         (  <Spinner size="sm" color="primary" style={{marginLeft: 10}}/> ) :
         (
            <FormGroup>
            <InputLabel id={id} label={label} tooltip={tooltip}/>
            <Select
              isMulti
              name={name}
              id={id}
              value={selectedOptions}
              className={classNames(className, { 'border-danger': errors[name]?.message })}
              placeholder={placeholder}
              options={ selectOptions.map( row => ( { value: row["id"], label: row["title"]} ) ) }
              onBlur={onBlur}
              innerRef={ref}
              styles={customStyles}
              classNamePrefix="selectmulti"
              onChange={(newSelectedOptions) => {
                setSelectedOptions(newSelectedOptions);
                const value = newSelectedOptions.map( row => ( row["value"] ) )
                // чтобы обновлялись данные в react-hook-form
                formMethods.setValue(name, value);
                handleChange({ name: name, value: value });
              }}
              {...rest}
            />

            { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
          </FormGroup>
        )
      }
    </>
  )
};


export default FormInputSelectMulti;
