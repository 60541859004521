import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {RouteNames} from "router/routes-names";
import {useActions} from "hooks/useActions";
import OrderDetailsFields from './OrderDetailsFields';
import OrderContent from './OrderContent';
import OrderDetailsPay from './OrderDetailsPay';
import CardFooterSave from 'components/editor/CardFooterSave';
import ContentWithAsideLayout from 'layouts/ContentWithAsideLayout';
import {OrderColumnsDetails} from './OrderColumnsDetails';
import {EditFormProvider} from 'components/editor/EditFormProvider';

export const OrderDetails = ({orderItem = {}}) => {
  const orderId = orderItem?.id;
  const isEditOrder = orderId;
  const user = useSelector(state => state.auth.item);
  const initialData = isEditOrder ? orderItem : { order_status_id: '1', dispatcher_id: user.id  }
  const [order, setOrder] = useState(initialData);
  const [stayOnPage, setStayOnPage] = useState();

  const router = useHistory();

  const {isAdded, isUpdated} = useSelector(state => state.orders);
  const {ordersAdd, ordersEdit, ordersSetIsAdded, ordersSetIsUpdated, orderContentSetIsListDirty} = useActions();
  const ordersRouteName = 'ORDERS';


  const allColumnsList = OrderColumnsDetails();

  const onSubmit = (data, event) => {
    const sendData = {...order };
    console.log(sendData);
    if(isEditOrder) {
      ordersEdit([orderId, sendData]);
    } else {
      ordersAdd(sendData);
    }
  };

  useEffect(() => {
    if(isAdded || isUpdated) {
      ordersSetIsAdded(false);
      ordersSetIsUpdated(false);
      orderContentSetIsListDirty(true);
      setOrder(orderItem);
      if(stayOnPage) {
        setStayOnPage(false);
      } else {
        router.push(RouteNames.ORDERS)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  return (
    <EditFormProvider
      fields={allColumnsList}
      onSubmit={onSubmit}
      item={order}
      setItem={setOrder}
    >

      <ContentWithAsideLayout isStickyAside={false} aside={
        <OrderDetailsPay/>
      }
      footer={
        <CardFooterSave
            isAdd={!isEditOrder}
            cancelRouteName={ordersRouteName}
            setStayOnPage={setStayOnPage}
        />
      }>

        <OrderDetailsFields/>
        <OrderContent orderId={orderId} content={order.content}/>

      </ContentWithAsideLayout>
    </EditFormProvider>
  );
};


OrderDetails.propTypes = {
  order: PropTypes.object,
};

OrderDetails.defaultProps = {
  order: {}
};

export default OrderDetails;
