import React, {useEffect, useState} from 'react';
import {CardBody, Col, Container, Spinner, Row} from 'reactstrap';
import SPRow from './SPRow';
import {isIterableArray} from 'helpers/utils';
import ButtonIcon from 'components/common/ButtonIcon';
import {useSelector} from "react-redux";
import sortBy from 'lodash/sortBy';
import {useObjectActions} from "hooks/useActions";
import Error from 'components/common/Error';

const SPEditorByRow = ({
  getList,
  setList,
  add,
  remove,
  edit,
  sort,
  namespace,
  colsInputs,
  defaultValues={},
  canEdit=1,
  canDelete=1,
  canAdd=1,
  deleteTooltip='Удалить',
}) => {

  const {list, isLoading, error, isAdded, isUpdated} = useSelector(state => state[namespace]);
  const {setIsUpdated, setIsAdded} = useObjectActions(namespace);
  const newId = -1;
  const [disableAdd, setDisableAdd] = useState(false);

  useEffect(() => {
      getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // если в списке было что-то добавлено или обновлено, то сбрасываем флаги и удаляем временные строки в таблице
  useEffect(() => {
    if(isAdded || isUpdated) {
      setList(list.filter(mtd => mtd.id !== newId));
      setIsUpdated(false);
      setIsAdded(false);
      setDisableAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  let sortedList = sort(list)
  // новую строку ставим всегда в конец
  sortedList = sortBy(sortedList, (row) => ( row.id === -1 ? 1 : -1 ) );

  // Add pay method
  const addRow = () => {
    // новую строку ставим всегда в конец
    setList([...list, {id: newId, ...defaultValues }]);
  }

  // Confirm adding method
  const confirmAddRow = (item) => {
    const sendData = {...item};
    delete sendData.id;
    add(sendData);
  }

  const cancel = (rowId) => {
    if (rowId < 0) {
      setList(list.filter(mtd => mtd.id !== rowId));
    }
    setDisableAdd(false);
  }

  return(
    <CardBody className="bg-light">
      <Error message={error}/>

      <Container className="bg-white">
      { isIterableArray(sortedList) &&
        <Row className="fs--1 px-1 pt-3 pb-1 font-weight-semi-bold">
          {colsInputs.map(column =>
            <Col {...column.colProps} className={"py-1 "+column.classes} key={column.name}>{column.header}</Col>
          )}
          <Col></Col>
        </Row>
      }
      { isIterableArray(sortedList) &&
        sortedList.map(row => (
          <SPRow
            item={row}
            key={row.id}
            handleCreate={confirmAddRow}
            handleRemove={remove}
            handleCancel={cancel}
            handleEdit={edit}
            disableBth={isLoading}
            colsInputs={colsInputs}
            canEdit={canEdit}
            canDelete={canDelete}
            deleteTooltip={deleteTooltip}
            namespace={namespace}
            isUpdated={isUpdated}
            isAdded={isAdded}
          />
        ))
      }
      { canAdd && <Row>
          <Col className="py-2">
            <ButtonIcon
              color="falcon-default"
              size="sm"
              icon="plus"
              onClick={()=>{addRow(); setDisableAdd(true)}}
              disabled={disableAdd}>
                Добавить
            </ButtonIcon>
          </Col>
      </Row> }
      {isLoading &&
            <Spinner size="sm" color="primary" style={{marginLeft: 10}}/>
      }
    </Container>
    <div style={{display: 'flex'}} className="pt-3">

    </div>
  </CardBody>
)

};

export default SPEditorByRow;
