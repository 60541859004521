import Login from "pages/login/Login";
import Dashboard from "pages/dashboard/Dashboard";
import Users from "pages/users/Users";
import Orders from "pages/orders/Orders";
import OrdersAdd from "pages/orders/OrdersAdd";
import OrdersEdit from "pages/orders/OrdersEdit";
import UsersAdd from "pages/users/UsersAdd";
import UsersEdit from "pages/users/UsersEdit";
import Drivers from "pages/drivers/Drivers";
import DriversAdd from "pages/drivers/DriversAdd";
import DriversEdit from "pages/drivers/DriversEdit";
import Owners from "pages/owners/Owners";
import OwnersAdd from "pages/owners/OwnersAdd";
import OwnersEdit from "pages/owners/OwnersEdit";
import Vehicles from "pages/vehicles/Vehicles";
import VehiclesAdd from "pages/vehicles/VehiclesAdd";
import VehiclesEdit from "pages/vehicles/VehiclesEdit";
import Params from "pages/params/Params";
import {RouteNames} from "router/routes-names";
import PayMethods from 'pages/payMethods/PayMethods';
import Brokers from "pages/brokers/Brokers";
import Cashflow from "pages/cashflow/Cashflow";

export const privateRoutes = [
    {path: RouteNames.DASHBOARD, component: Dashboard, exact: true},
    {path: RouteNames.USERS, component: Users, exact: true},
    {path: RouteNames.USERS_ADD, component: UsersAdd, exact: true},
    {path: RouteNames.USERS+'/:id', component: UsersEdit, exact: true},
    {path: RouteNames.PAYMETHODS, component: PayMethods, exact: true},
    {path: RouteNames.ORDERS, component: Orders, exact: true},
    {path: RouteNames.ORDERS_ADD, component: OrdersAdd, exact: true},
    {path: RouteNames.ORDERS+'/:id', component: OrdersEdit, exact: true},
    {path: RouteNames.DRIVERS, component: Drivers, exact: true},
    {path: RouteNames.DRIVERS_ADD, component: DriversAdd, exact: true},
    {path: RouteNames.DRIVERS+'/:id', component: DriversEdit, exact: true},
    {path: RouteNames.OWNERS, component: Owners, exact: true},
    {path: RouteNames.OWNERS_ADD, component: OwnersAdd, exact: true},
    {path: RouteNames.OWNERS+'/:id', component: OwnersEdit, exact: true},
    {path: RouteNames.VEHICLES, component: Vehicles, exact: true},
    {path: RouteNames.VEHICLES_ADD, component: VehiclesAdd, exact: true},
    {path: RouteNames.VEHICLES+'/:id', component: VehiclesEdit, exact: true},
    //страница редактора настроек
    {path: RouteNames.PAGE_PARAMS, component: Params, exact: true},
    {path: RouteNames.BROKERS, component: Brokers, exact: true},
    {path: RouteNames.CASHFLOW, component: Cashflow, exact: true},
]

export const publicRoutes = [
    {path: RouteNames.LOGIN, component: Login, exact: true},
]

const router = { RouteNames, privateRoutes, publicRoutes };

export default router;
