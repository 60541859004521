// https://coderoad.ru/62977443/%D0%9A%D0%B0%D0%BA-%D0%BF%D0%BE%D0%B2%D1%82%D0%BE%D1%80%D0%BD%D0%BE-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D0%BB%D0%BE%D0%B3%D0%B8%D0%BA%D1%83-%D1%80%D0%B5%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%B0-%D0%B2-%D1%84%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D0%B8-Redux-Toolkit-createSlice

export const hasPrefix = (action, prefix) => action.type.startsWith(prefix);
export const isPending = (action) => action.type.endsWith("/pending");
export const isFulfilled = (action) => action.type.endsWith("/fulfilled");
export const isRejected = (action) => action.type.endsWith("/rejected");

export const isPendingAction = (prefix = '') => (action) => {
  return hasPrefix(action, prefix) && isPending(action);
};

export const isRejectedAction = (prefix = '') => (action) => {
  return hasPrefix(action, prefix) && isRejected(action);
};

export const isFulfilledAction = (prefix = '') => (action) => {
  return hasPrefix(action, prefix) && isFulfilled(action);
};
