import React, { useEffect, useState } from 'react';
import {CashflowAddForm} from "./CashflowAddForm";
import {Button, Modal, ModalBody} from "reactstrap";
import ButtonIcon from 'components/common/ButtonIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import {useActions} from "hooks/useActions";
import ImportModal from "./ImportModal";

const CashflowAddBlock = () => {

  const [modal, setModal] = useState(false);
  const [cashflowItem, setCashflowItem] = useState(false);
  const {isAdded} = useSelector(state => state.cashflow);
  const {cashflowSetIsAdded} = useActions();
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);

  const addInflow = () => {
    setCashflowItem({flow_type: 'inflow'});
    toggle();
  }

  const addOutflow = () => {
    setCashflowItem({flow_type: 'outflow'});
    toggle();
  }

  const toggle = () =>  (setModal(!modal));


  // закрываем окно после добавления
  useEffect(() => {
    if(isAdded) {
      setModal(false);
      cashflowSetIsAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded]);


  return (
    <>
      <ButtonIcon icon="plus" outline key="btnAddInflow" onClick={addInflow} transform="shrink-3 down-2" color="success" size="sm">
        Доход
      </ButtonIcon>
      <ButtonIcon icon="plus" outline key="btnAddOutflow" onClick={addOutflow} transform="shrink-3 down-2" color="danger" size="sm" className="ml-1">
        Расход
      </ButtonIcon>

      <ButtonIcon icon="file-excel" outline key="btnImport" onClick={ () => setIsOpenImportModal(true) } transform="shrink-3 down-2" size="sm" className="ml-1">
        Импорт
      </ButtonIcon>

      <ImportModal
        isOpenModal={isOpenImportModal}
        setIsOpenModal={setIsOpenImportModal}
      />

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="mt-6"
        contentClassName="border-0"
        modalClassName="theme-modal"
        size="lg"
      >
        <ModalBody className="p-0">
          <div className="position-absolute t-0 r-0  z-index-1">
            <Button
              size="sm"
              className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
              onClick={toggle}
            >
              <FontAwesomeIcon icon="circle-xmark" transform="shrink-6 right-0.3 down-0.3" />
            </Button>
          </div>
          <div className="p-4">
            <CashflowAddForm cashflowItem={cashflowItem}/>
          </div>
        </ModalBody>
      </Modal>

    </>
  );
};

export default CashflowAddBlock;
