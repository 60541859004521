import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Button, Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import AppContext from '../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import UserACL from 'components/acl/UserACL';

const NavbarVerticalMenu = ({ routes, location }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const { setShowBurgerMenu } = useContext(AppContext);

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = name => {
    if (name === 'Widgets' || name === 'Documentation') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  return routes.map((route, index) => {
    const role = route.role;

    const linkProp = Object.assign({}, route);
    delete linkProp.b;

    if (!route.children) {
      return (
        <UserACL role={role}>
          <Fragment key={index}>
            {getHr(route.name)}
            <NavItem>
              {
                linkProp.link ?
                (
                  <Button tag="a" href={linkProp.link} target="_blank" color="link" size="sm">
                      <NavbarVerticalMenuItem route={route} />
                  </Button>
                )
                :
                (
                  <NavLink className="nav-link" {...linkProp} onClick={() => setShowBurgerMenu(false)}>
                    <NavbarVerticalMenuItem route={route} />
                  </NavLink>

                )
              }
            </NavItem>
          </Fragment>
        </UserACL>
      );
    }
    return (
      <UserACL role={role}>
        <Fragment key={index}>
          {getHr(route.name)}
          <NavItem>
            <BootstrapNavLink
              onClick={e => toggleOpened(e, index)}
              className="dropdown-indicator cursor-pointer"
              aria-expanded={openedIndex === index}
            >
              <NavbarVerticalMenuItem route={route} />
            </BootstrapNavLink>
            <Collapse isOpen={openedIndex === index}>
              <Nav>
                <NavbarVerticalMenu routes={route.children} location={location} />
              </Nav>
            </Collapse>
          </NavItem>
        </Fragment>
      </UserACL>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
