import React, { createRef, Fragment, useEffect, useState } from 'react';
import {Alert, Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ButtonIcon from 'components/common/ButtonIcon';
import Error from 'components/common/Error';
import TableEditorWithSelectedOptions from './TableEditor/TableEditorWithSelectedOptions';
import TableEditorTable from './TableEditor/TableEditorTable';
import {Link} from 'react-router-dom';
import './TableEditor/TableEditor.css';
import {useSelector} from "react-redux";
import {useObjectActions} from "hooks/useActions";
import uuid from 'uuid/v1';
import {isIterableArray} from 'helpers/utils';
import Loader from 'components/common/Loader';
import {useQueryParams, NumberParam, StringParam, JsonParam, withDefault} from 'use-query-params';

// работа с react-bootstrap-table-next
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
const TableEditor = ({ title, withSelectedOptions, actionButtons, columns,
            extraTableFilters, extraActionButtons, sort, onRowClick,
            namespace, isEditable, emptyText }) => {



  const table = createRef();
  const [isSelected, setIsSelected] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const {list, isListDirty, error, isGetListLoading, page: currentPage, sortBy, sortOrder, filter} = useSelector(state => state[namespace]);
  const {getList, setPage, setFilter, setSort} = useObjectActions(namespace);

  const [query, setQuery] = useQueryParams({
    p: withDefault(NumberParam, currentPage),
    sortBy: withDefault(StringParam, sortBy),
    sortOrder: withDefault(StringParam, sortOrder),
    filter: withDefault(JsonParam, filter),
  });


  // при первой загрузке подставляем параметры сортировка, пагинации, фильтров из query строки в браузере
  useEffect(() => {
    if(isFirstLoading) {
      if(currentPage !== query.p) {
        setPage(query.p)
      }
      if(sortBy !== query.sortBy || sortOrder !== query.sortOrder) {
        setSort({ sortBy: query.sortBy, sortOrder: query.sortOrder })
      }
      if(query.filter) {
        setFilter(query.filter);
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoading])

  // перезагружаем список, как только меняются какие-то параметры (страница, фильтры, сортировка)
  useEffect(() => {
    if(isListDirty) {
      getList({withPagination: true});
      setIsFirstLoading(false)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListDirty])


  return (
    <Card className="mb-3">
      <FalconCardHeader title={title} light={false}>
        {isSelected ? (
          <TableEditorWithSelectedOptions
            data={list}
            table={table}
            withSelectedOptions={withSelectedOptions}
          />
        ) : (
          <Fragment>
             {actionButtons.map(button => {
                let buttonProps = {};
                if(button.link) {
                  buttonProps = {
                    tag: Link,
                    to: button.link,
                  };
                } else if (button.onClick) {
                  buttonProps = {
                    onClick: button.onClick,
                  };
                } else if (button.href) {
                  buttonProps = {
                    tag: 'a',
                    href: button.href,
                    target: button.target,
                  };
                }
                return <ButtonIcon icon={button.icon} key={uuid()}
                                      {...buttonProps}
                                      transform="shrink-3 down-2" color="falcon-default" size="sm" className="ml-2">
                                      {button.title}
                                   </ButtonIcon>
               }
             )}

            {extraActionButtons}

          </Fragment>
        )}
      </FalconCardHeader>


      {(() => {
              if (isIterableArray(list) || !isFirstLoading) {
                  return (

                        <>
                          {extraTableFilters}

                          <Error className="mx-3" message={error}/>

                          <TableEditorTable
                             setIsSelected={setIsSelected}
                             table={table}
                             data={list}
                             columns={columns}
                             sort={sort}
                             onRowClick={onRowClick}
                             namespace={namespace}
                             isEditable={isEditable}
                             query={query}
                             setQuery={setQuery}
                          />
                       </>

                  )
              } else if (isGetListLoading) {
                  return (
                    <Loader/>
                  )
              } else {

                return (

                      <CardBody className="fs--1 p-0">
                        <Row className="p-card">
                          <Col>

                            <Error className="mx-3" message={error}/>

                            <Alert color="info" className="mb-0">
                              { emptyText ? emptyText : 'Записей нет' }
                            </Alert>
                          </Col>
                        </Row>
                      </CardBody>

                  )
              }
            })()}


    </Card>
  );
}

//
// TableEditor.propTypes = {
//   title: PropTypes.string.isRequired,
//   withSelectedOptions: PropTypes.array,
//   actionButtons: PropTypes.array,
//   data: PropTypes.array,
//   columns: PropTypes.array.isRequired,
//   sort: PropTypes.func,
//   error: PropTypes.string,
// };

TableEditor.defaultProps = {
  withSelectedOptions: [],
  actionButtons: [],
  data: [],
  sort: (data) => { return data },
};

export default TableEditor;