import React, {useEffect} from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FormInput from 'components/input/FormInput';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";


export const DriverFormFields = ({ handleDriverChange, driver }) => {
  const isEditProfile = driver.id;
  const {companiesGetList} = useActions();
  const { list: companiesList, isLoading: companiesLoading } = useSelector(state => state.companies);

  useEffect(() => {
    companiesGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Card className="mb-3">
        <FalconCardHeader title={isEditProfile ? 'Редактировать водителя' : 'Добавить водителя'} light={false} />
        <CardBody className="bg-light">
          <Row>
            <Col lg={3}>
              <FormInput
                label="ФИО"
                placeholder="ФИО"
                name="title"
                id="title"
                value={driver['title']}
                handleChange={handleDriverChange}
              />
            </Col>
             <Col lg={3}>
              <FormInput
                type="select"
                name="company_id"
                id="company_id"
                label="Компания"
                options={companiesList}
                isLoading={companiesLoading}
                placeholder="Выберите компанию"
                handleChange={handleDriverChange}
                value={driver['company_id']}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <FormInput
                label="Номер телефона"
                placeholder="Номер телефона"
                name="phone"
                id="phone"
                value={driver['phone']}
                handleChange={handleDriverChange}
              />
            </Col>
            <Col lg={3}>
              <FormInput
                label="Email"
                placeholder="Email"
                name="email"
                id="email"
                value={driver['email']}
                handleChange={handleDriverChange}
              />
            </Col>
          </Row>
      </CardBody>
    </Card>
  );
};

export default DriverFormFields;
