import * as services from "services/index";
import reducerObjectEditor from "./baseClass/reducerObjectEditor";
import authReducer from './auth'
import systemReducer from './system'

const owners = new reducerObjectEditor('owners', services.OwnerService)
const users = new reducerObjectEditor('users', services.UserService)
const paymethods = new reducerObjectEditor('paymethods', services.PayMethodService)
const orders = new reducerObjectEditor('orders', services.OrderService, { useFilters: true, initialState: { perPage: 30, sortBy: 'id', sortOrder: 'desc' }  })
const drivers = new reducerObjectEditor('drivers', services.DriverService)
const trucks = new reducerObjectEditor('trucks', services.TruckService)
const roles = new reducerObjectEditor('roles', services.RoleService)
const orderStatuses = new reducerObjectEditor('orderStatuses', services.OrderStatusService)
const brokers = new reducerObjectEditor('brokers', services.BrokerService)
const paytypes = new reducerObjectEditor('paytypes', services.PaytypeService)
const quickpayTypes = new reducerObjectEditor('quickpayTypes', services.QuickpayTypeService)
const params = new reducerObjectEditor('params', services.ParamService)
const orderContent = new reducerObjectEditor('orderContent', services.OrderContentService, { useFilters: true, initialState: { perPage: 0 } })
const cashflow = new reducerObjectEditor('cashflow', services.CashflowService, { useFilters: true, initialState: { perPage: 30, sortBy: 'date', sortOrder: 'desc' } })
const flowTypes = new reducerObjectEditor('flowTypes', services.FlowTypeService)
const cashflowOperationType = new reducerObjectEditor('cashflowOperationType', services.CashflowOperationTypeService)
const wallet = new reducerObjectEditor('wallet', services.WalletService)
const companies = new reducerObjectEditor('companies', services.CompanyService)
const vehicles = new reducerObjectEditor('vehicles', services.VehicleService)
const vehicleTypes = new reducerObjectEditor('vehicleTypes', services.VehicleTypeService)

const reducers = {
  auth: authReducer,
  users: users.reducer(),
  roles: roles.reducer(),
  drivers: drivers.reducer(),
  paymethods: paymethods.reducer(),
  system: systemReducer,
  orders: orders.reducer(),
  owners: owners.reducer(),
  trucks: trucks.reducer(),
  orderStatuses: orderStatuses.reducer(),
  brokers: brokers.reducer(),
  paytypes: paytypes.reducer(),
  quickpayTypes: quickpayTypes.reducer(),
  params: params.reducer(),
  orderContent: orderContent.reducer(),
  cashflow: cashflow.reducer(),
  flowTypes: flowTypes.reducer(),
  cashflowOperationType: cashflowOperationType.reducer(),
  wallet: wallet.reducer(),
  companies: companies.reducer(),
  vehicles: vehicles.reducer(),
  vehicleTypes: vehicleTypes.reducer(),
};

const actions = {
  owners: owners.actions(),
  users: users.actions(),
  paymethods: paymethods.actions(),
  orders: orders.actions(),
  drivers: drivers.actions(),
  trucks: trucks.actions(),
  roles: roles.actions(),
  orderStatuses: orderStatuses.actions(),
  brokers: brokers.actions(),
  paytypes: paytypes.actions(),
  quickpayTypes: quickpayTypes.actions(),
  params: params.actions(),
  orderContent: orderContent.actions(),
  cashflow: cashflow.actions(),
  flowTypes: flowTypes.actions(),
  cashflowOperationType: cashflowOperationType.actions(),
  wallet: wallet.actions(),
  companies: companies.actions(),
  vehicles: vehicles.actions(),
  vehicleTypes: vehicleTypes.actions(),
};

export {
  reducers,
  actions
};