import React from 'react';
import {useActions} from "hooks/useActions";
import TableEditor from "components/editor/TableEditor";
import {CashflowColumns} from "./components/CashflowColumns";
import CashflowAddBlock from "./components/CashflowAddBlock";

const Cashflow = () => {
  const namespace="cashflow";
  const {cashflowRemove} = useActions();

  const withSelectedOptions = [
      {value: "Delete", title: "Удалить", callback: cashflowRemove }
  ];


  const allColumnsList = CashflowColumns();

  return (
    <TableEditor
      title="ДДС"
      columns={allColumnsList}
      withSelectedOptions={withSelectedOptions}
      extraActionButtons={<CashflowAddBlock/>}
      namespace={namespace}
      isEditable={true}
    />
  );
};

export default Cashflow;
