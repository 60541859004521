import {useSelector} from "react-redux";
import {useObjectActions} from "hooks/useActions";

export const useListItem = (namespace) => {
  const {list} = useSelector(state => state[namespace]);
  const {getList} = useObjectActions(namespace);

  getList();

  const getItem = (itemId) => {
    const item = list.find((item) => item.id === itemId) || {}
    return item;
  }

  return {getItem};
}