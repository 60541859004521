import { useContext, useEffect, useState } from "react";
import { ConfirmContext } from "context/ConfirmContextProvider";
// взято отсюда
// https://medium.com/@jaredloson/a-replacement-for-window-confirm-using-promises-and-react-hooks-cfc011e76a7a
// https://codesandbox.io/s/use-confirm-mm1qz?from-embed=&file=/src/List.js
const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = ({ header, cancelText='Отменить', confirmText='Подтвердить', message}) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({ header, cancelText, confirmText, message, isOpen: true, proceed: resolve, cancel: reject });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({ header: "", message: "", cancelText: 'Отменить', confirmText: 'Подтвердить', proceed: null, cancel: null, isOpen: false });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed
  };
};

export default useConfirm;
