import React, { Fragment, useEffect } from 'react';
import {UserProfile} from "./components/UserProfile";
import {useParams} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';

const UsersEdit = () => {
  const params = useParams()
  const {usersGetItem} = useActions()
  const {isLoading, item} = useSelector(state => state.users);

  useEffect(() => {
      usersGetItem(params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
     <Fragment>
          { isLoading ? ( <Loader/> ) : ( <UserProfile userItem={item}/> ) }
     </Fragment>
  );
};

export default UsersEdit;
