import React, { useState } from 'react';
import {Button, Form, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {useForm, FormProvider} from 'react-hook-form';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import FormInput from 'components/input/FormInput';
import {OrderService} from "services/index";
import Error from 'components/common/Error';

const ImportModal = ({isOpenModal, setIsOpenModal}) => {
  const toggle = () => setIsOpenModal(!isOpenModal);
  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [uploadFile, setUploadFile] = useState();

  // TODO: проверка на формат файла - не срабатывает submit при нормальной загрузке
  const supportedFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

  let ValidSchema = yup.object().shape({
    upload_file: yup.mixed().required('Выберите файл')
        .test(
          'fileFormat',
          (field) => { console.log(field); return 'Вы выбрали файл типа '+field.value[0].type+'. Выберите файл в формате Excel' },
          (value) => { console.log(value[0].type); return value === null || (value && supportedFormats.includes(value[0].type)) }
        )
  });
  const formMethods = useForm({resolver: yupResolver(ValidSchema) });
  const {handleSubmit} = formMethods;
  const {isDirty} = formMethods.formState;

  const onSubmit = async (data) => {
    setError('');
    let formData = new FormData();
    formData.append('upload_file', uploadFile);
    try {
      await OrderService.upload(formData);
      setUploadFile('');
      setSuccess(true);
      return true;
    } catch (e) {
      setError(e.response.data.errors[0].message);
      return false;
    }
  }

  const handleChange = (data) => {
    setUploadFile(data.value);
  }

  return (
    <Modal isOpen={isOpenModal} toggle={toggle} className="mt-6" contentClassName="border-0" modalClassName="theme-modal" size="lg">
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
            Импортировать список заказов из Excel файла
          </ModalHeader>
          <ModalBody>
            <p>Выберите Excel файл со списком заказов. Таблица заказов должна находиться на первом листе. В заголовках столбцов должны быть указаны названия полей. </p>
              <FormInput
                type="file"
                label="Выберите Excel файл"
                placeholder="Excel файл"
                name="upload_file"
                id="upload_file"
                value={uploadFile}
                handleChange={handleChange}
              />

              <Button color="primary" type="submit" disabled={!isDirty} className="mb-2">
                Загрузить
              </Button>
              <Error message={error}/>
              {success &&
                <div className="d-flex justify-content-between fs--1 mb-1 text-success">
                  <p className="mb-0">Файл успешно загружен. В ближайшее время он будет обработан</p>
                </div>
              }
          </ModalBody>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ImportModal;
