import React, { Fragment } from 'react';
import {Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getPaginationArray} from 'helpers/utils';

// работа с react-bootstrap-table-next
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
const TableEditorTablePagination = ({ paginationProps, isFiltered, clearFilter }) => {

  const lastIndex = paginationProps.page * paginationProps.sizePerPage;

  const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1}  — {lastIndex > totalSize ? totalSize : lastIndex} из {totalSize}
    </span>
  );

  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };


  return (

      <Row noGutters className="px-1 py-3 flex-center">
        <Col className="pl-3 fs--1">
          <CustomTotal {...paginationProps} lastIndex={lastIndex} />
          {/* <ButtonIcon */}
          {/*   color="link" */}
          {/*   size="sm" */}
          {/*   icon="chevron-right" */}
          {/*   iconAlign="right" */}
          {/*   transform="down-1 shrink-4" */}
          {/*   className="px-0 font-weight-semi-bold" */}
          {/*   onClick={() => handleViewAll(paginationProps, paginationProps.totalSize)} */}
          {/* > */}
          {/*   view all */}
          {/* </ButtonIcon> */}
          {isFiltered &&
            <>
              <Button color="link" size="sm" type="button" onClick={clearFilter} id="clearFilter">
                <FontAwesomeIcon icon="xmark" className="text-primary fs-2" transform="shrink-3" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="clearFilter">Сбросить все фильтры</UncontrolledTooltip>
            </>
          }
        </Col>

        <Col xs="auto">
          <Button
            color="falcon-default"
            size="sm"
            onClick={handlePrevPage(paginationProps)}
            disabled={paginationProps.page === 1}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </Button>
          {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
            <Button
              color={paginationProps.page === pageNo ? 'primary' : 'falcon-default'}
              size="sm"
              className="ml-2"
              onClick={() => paginationProps.onPageChange(pageNo)}
              key={pageNo}
            >
              {pageNo}
            </Button>
          ))}
          <Button
            color="falcon-default"
            size="sm"
            className="ml-2"
            onClick={handleNextPage(paginationProps)}
            disabled={lastIndex >= paginationProps.totalSize}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </Col>
      </Row>
  );

}


export default TableEditorTablePagination;