import React, { useState, useEffect } from 'react';
import {Button, CustomInput, InputGroup } from 'reactstrap';

// работа с react-bootstrap-table-next
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
const TableEditorWithSelectedOptions = ({ table, data, withSelectedOptions }) => {

  const [warningMessage, setWarningMessage] = useState('');
  const [valueForChangeAction, setValueForChangeAction] = useState('');

  useEffect(() => {
    setValueForChangeAction('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const apply = () => {
    const selectedAction = withSelectedOptions.find( (action) => action.value === valueForChangeAction );
    if(!selectedAction) {
      setWarningMessage('Выберите действие');
    } else {
      const idSelectedRows = table.current.selectionContext.selected;
      (async () => {
        await selectedAction.callback(idSelectedRows);
        setWarningMessage('');
      })();
    }
  }

  return (

      <InputGroup size="sm" className="input-group input-group-sm">
        {warningMessage &&
          <span className="text-warning fs--1 mr-2 mt-1">
            {warningMessage}
          </span>
        }
        <CustomInput type="select" id="bulk-select" value={valueForChangeAction} onChange={(e)=> setValueForChangeAction(e.target.value)}>
          <option>С отмеченными</option>
          {withSelectedOptions.map(option =>
             <option key={option} value={option.value}>{option.title}</option>
          )}
        </CustomInput>
        <Button color="falcon-default" size="sm" className="ml-2" onClick={apply}>
          Применить
        </Button>
      </InputGroup>

  );
}


export default TableEditorWithSelectedOptions;