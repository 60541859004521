// import { version } from './config';
import {RouteNames} from "router/routes-names";


export const ordersRoutes = {
  name: 'Заказы',
  to: RouteNames.ORDERS,
  icon: 'cart-plus',
  // children: [
  //   { to: '/orders', name: 'Заказы', exact: true },
  //   // { to: '/clients', name: 'Покупатели', exact: true },
  // ]
};
export const cashflowRoutes = {
  name: 'ДДС',
  to: RouteNames.CASHFLOW,
  icon: 'star',
  // children: [
  //   { to: '/orders', name: 'Заказы', exact: true },
  //   // { to: '/clients', name: 'Покупатели', exact: true },
  // ]
};

export const manageRoutes = {
  name: 'Управление',
  to: '/users',
  exact: true,
  icon: 'puzzle-piece',
  children: [
    { to: RouteNames.USERS, name: 'Пользователи', exact: true },
    { to: RouteNames.OWNERS, name: 'Собственники', exact: true },
    { to: RouteNames.DRIVERS, name: 'Водители', exact: true },
    { to: RouteNames.VEHICLES, name: 'Транспортные средства', exact: true },
    { to: RouteNames.BROKERS, name: 'Брокеры', exact: true },
    { to: RouteNames.PAYMETHODS, name: 'Методы оплаты', exact: true },
    { to: RouteNames.PAGE_PARAMS, name: 'Настройки', exact: true },
  ]
};



const routes = [
  ordersRoutes,
  cashflowRoutes,
  manageRoutes,
  // pageRoutes,
  // chatRoutes,
  // kanbanRoutes,
  // calenderRoutes,
  // emailRoutes,
  // authenticationRoutes,
  // ECommerceRoutes
  // widgetsRoutes,
  // componentRoutes,
  // utilityRoutes,
  // pluginRoutes,
  // documentationRoutes,
  // changelogRoutes
];

export default routes;