import React from 'react';
import TableEditor from "components/editor/TableEditor";
import {OwnerColumns} from "./components/OwnerColumns";
import {RouteNames} from "router/routes-names";
import {useHistory} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";


const Owners = () => {
  const namespace="owners";
  const router = useHistory()
  const {ownersRemove} = useActions()
  const {error} = useSelector(state => state.owners);

  const withSelectedOptions = [
    {value: "delete", title: "Удалить", callback: ownersRemove },
  ];

  const actionButtons = [
      {icon: "plus", title: "Добавить", link: RouteNames.OWNERS_ADD}
  ];
  // список действий над выбранными элементами
  const itemActionsMenu = (row) => [
    {title: "Редактировать", callback: (row) => router.push(RouteNames.OWNERS_EDIT(row.id)) },
    {title: "Удалить", callback: async (owner) => ownersRemove([owner.id]), className: "text-danger" }
  ]

  return (
    <TableEditor
      title="Владельцы, инвесторы транспортных средств"
      columns={OwnerColumns(itemActionsMenu)}
      withSelectedOptions={withSelectedOptions}
      actionButtons={actionButtons}
      error={error}
      namespace={namespace}
    />
  );
};

export default Owners;