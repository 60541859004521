import React, {useEffect, useState} from 'react';
import orderBy from 'lodash/orderBy';
import {Card} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SPEditorByRowTemporary from 'components/editor/SPEditorByRowTemporary';
import {useEditFormContext} from 'hooks/useEditFormContext';

const OrderContent = ({ orderId=0, content=[] }) => {
  // название поля с содержимым заказа в форме
  const formFieldName = 'content';
  const [currentContent, setCurrentContent] = useState(content);
  const {formMethods, handleInputChange} = useEditFormContext();

  // регистрируем поле content в родительской форме, чтобы установить ему значение
  useEffect(() => {
    formMethods.register(formFieldName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    formMethods.setValue(formFieldName, currentContent, { shouldDirty: true });
    handleInputChange({name: formFieldName, value: currentContent});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentContent)]);

  const editorHeader = "Авто";
  const inputs = [
    {
      header: "Автомобиль",
      type: "text",
      name: "title",
      required: {
        required: 'Введите автомобиль'
      },
    },
    {
      header: "Комментарий",
      type: "text",
      name: "comment"
    }
  ];
  const sort = (list) => {
    return orderBy(list, ['id'] );
  };

  const defaultValues = { order_id: orderId };

  return (
    <Card className="mb-3">
      <FalconCardHeader title={editorHeader} light={false} />
        <SPEditorByRowTemporary
          sort={sort}
          colsInputs={inputs}
          list={currentContent}
          setList={setCurrentContent}
          defaultValues={defaultValues}
        />
    </Card>
  );
};


export default OrderContent;
