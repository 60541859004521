import {isPendingAction, isRejectedAction, isFulfilledAction} from "helpers/reducersMatcher";

export const baseObjectInitial = {
  list: [],
  item: {},
  error: '',
  isLoading: false,
  isGetListLoading: false,
  isGetItemLoading: false,
  isListDirty: true,
  totalSize: 0,
  page: 1,
  perPage: 30,
}

export const baseObjectReducers = {
  setList(state, action) {
    state.list = action.payload
  },
  setItem(state, action) {
    state.item = action.payload
  },
  setIsLoading(state, action) {
    state.isLoading = action.payload
  },
  setError(state, action) {
    state.error = action.payload
  },
  setIsListDirty(state, action) {
    state.isListDirty = action.payload
  },
  setPage(state, action) {
    if(state.page !== action.payload) {
      state.page = action.payload
      state.isListDirty = true
    }
  },
  setPerPage(state, action) {
    state.perPage = action.payload
    state.isListDirty = true
  }
}


// обработка pending, fulfilled и  rejected для всех запросов
export const baseObjectExtraReducers = (builder, prefix) => {
    builder
    .addMatcher(isPendingAction(prefix), state => {
      state.isLoading = true
      state.error = '';
    })
    .addMatcher(isFulfilledAction(prefix), (state, action) => {
      state.isLoading = false
      if(action.payload?.errors) {
        state.error = action.payload.errors;
      } else {
        state.error = '';
      }
    })
    .addMatcher(isRejectedAction(prefix), (state, action) => {
      console.log(prefix);
      console.log(action);
      if(action.payload?.response.data.errors?.length) {
        state.error = action.payload.response.data.errors[0].message
      } else {
        state.error = 'Не удалось получить данные'
      }
      state.isLoading = false
    })
    .addMatcher(isPendingAction(prefix+'/getItem'), state => {
      state.isGetItemLoading = true
    })
    .addMatcher(isFulfilledAction(prefix+'/getItem'), state => {
      state.isGetItemLoading = false
    })
    .addMatcher(isRejectedAction(prefix+'/getItem'), state => {
      state.isGetItemLoading = false
    })
    .addMatcher(isPendingAction(prefix+'/getList'), state => {
      state.isGetListLoading = true
    })
    .addMatcher(isFulfilledAction(prefix+'/getList'), state => {
      state.isGetListLoading = false
    })
    .addMatcher(isRejectedAction(prefix+'/getList'), state => {
      state.isGetListLoading = false
    })

    return builder;
}
