export const SystemActionEnum = {
  SET_ERROR: 'SET_SYSTEM_ERROR'
}

const initialState = {
  systemError: {
    name: '',
    message: ''
  }
};
export default function systemReducer(state = initialState, action) {
  switch (action.type) {
    case SystemActionEnum.SET_ERROR:
      return {...state, systemError: action.payload }
    default:
      return state;
  }
}