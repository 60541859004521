import React, {useEffect} from 'react';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {RouteNames} from "router/routes-names";
import PropTypes from 'prop-types';
import FormInput from 'components/input/FormInput';
import CardFooterSave from 'components/editor/CardFooterSave';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm, FormProvider} from 'react-hook-form';

export const OwnerProfile = () => {

  const router = useHistory();
  const {error, isAdded, isUpdated, item: owner} = useSelector(state => state.owners);
  const {ownersAdd, ownersEdit, ownersSetIsUpdated, ownersSetIsAdded} = useActions();
  const ownerId = owner.id;
  const isEditProfile = ownerId;
  const isAddProfile = !ownerId;
  const namespace = 'OWNERS';

  let ValidSchema = yup.object().shape({});
  const formMethods = useForm({resolver: yupResolver(ValidSchema) });
  const {handleSubmit} = formMethods;
  const {isDirty} = formMethods.formState;

  const onSubmit = async (data, e) => {
    console.log(data);
    const owner = {
      title: data.title
    };

    if(isEditProfile) {
      ownersEdit([ownerId, owner]);
    } else {
      ownersAdd(owner);
    }
  };

  useEffect(() => {
    if(isUpdated) {
      ownersSetIsUpdated(false);
      router.push(RouteNames[namespace])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    if(isAdded && ownerId) {
      ownersSetIsAdded(false);
      router.push(RouteNames.OWNERS_EDIT(ownerId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded]);



  return (
    <>
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <FalconCardHeader title={isEditProfile ? 'Редактировать владельца' : 'Добавить владельца'} light={false} />
          <CardBody className="bg-light">
              <Row>
                <Col lg={6}>
                   <FormInput
                    handleChange={()=>{}}
                    label="Название"
                    placeholder="Название"
                    name="title"
                    id="title"
                    value={owner['title']}
                  />
                </Col>
              </Row>
          </CardBody>
        </Card>

        <CardFooterSave
          dirty={isDirty}
          error={error}
          isAdd={isAddProfile}
          cancelRouteName={namespace}
        />
      </Form>
    </FormProvider>
    </>

  );
};


OwnerProfile.propTypes = {
  owner: PropTypes.object
};

OwnerProfile.defaultProps = {
  owner: {}
};

export default OwnerProfile;
