import React, {useEffect, useState} from 'react';
import {Button, Col, Row, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useForm, FormProvider} from "react-hook-form";
import FormInput from 'components/input/FormInput';

const SPRow = ({
  item,
  handleCreate,
  handleRemove,
  handleCancel,
  handleEdit,
  disableBth,
  colsInputs,
  canEdit,
  canDelete,
  deleteTooltip,
  namespace,
  isAdded,
  isUpdated,
}) => {
  const [row, setRow] = useState(item);

  const formMethods = useForm({ mode: 'onSubmit' });
  const {handleSubmit} = formMethods;
  const {isDirty} = formMethods.formState;
  const isNew = row.id < 0;
  const [isEditing, setIsEditing] = useState(isNew ? true : false);

  useEffect(() => {
    if(isEditing && (isAdded || isUpdated)) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  const handleInputChange = ({ value, name }) => {
      console.log({name, value});
      setRow({ ...row, [name]: value })
  };

  const startEdit = (e) => {
    setIsEditing(true);
    e.preventDefault();
  }

  const onSubmit = (formData) => {
    if (isNew) {
      handleCreate(row);
    } else {
      if(isDirty) {
        handleEdit([row.id, row]);
      } else {
        setIsEditing(false);
      }
    }
  }

  const removePayMethod = (e) => {
    handleRemove([row.id]);
    e.preventDefault();
  }
  
  const cancel = (e) => {
    handleCancel(row.id);
    setIsEditing(false);
    e.preventDefault();
  }

  return (
    <FormProvider {...formMethods}>

      <Row className="fs--1 font-weight-semi-bold">
        {colsInputs.map((column, index )=>
          <Col key={index} {...column.colProps} className={column.classes}>
           { column.disabledEdit ? (
              (column.name === 'id' && isNew) ? '' : row[column.name]
            ) : (
              <FormInput
                bsSize="sm"
                placeholder={column.header}
                type={column.type}
                name={column.name}
                id={column.name}
                value={row[column.name]}
                // Можем редактировать поле если disabledEdit=false
                disabled={(column.disabledEdit && !isNew) || !isEditing}
                handleChange={handleInputChange}
                options={column.options}
                isLoading={column.isLoading}
              />
            ) }
        </Col>
        )}

        { isEditing ? (
          <Col className="text-nowrap">
          {canEdit &&  <> <Button color="link" size="sm" type="submit" disabled={disableBth} onClick={handleSubmit(onSubmit)} id="confirmEdit">
              <FontAwesomeIcon icon="circle-check" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="confirmEdit">Сохранить изменения</UncontrolledTooltip>
            </>
          }
          {canDelete &&  <> <Button color="link" size="sm" type="button" onClick={cancel} disabled={disableBth} id="cancel">
              <FontAwesomeIcon icon="xmark" className="text-danger" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="cancel">Отменить</UncontrolledTooltip>
            </>
          }
          </Col>
        ) : (
          <Col className="text-nowrap">
          {canEdit && <>
            <Button color="link" size="sm" type="button" onClick={startEdit} disabled={disableBth} id="edit">
              <FontAwesomeIcon icon="pen-to-square" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="edit">Редактировать</UncontrolledTooltip>
            <Button color="link" size="sm" type="button" disabled={disableBth} onClick={removePayMethod} id="delete">
              <FontAwesomeIcon icon="trash-can" className="text-danger" transform="shrink-3" />
            </Button>
            <UncontrolledTooltip placement="bottom" target="delete">{deleteTooltip}</UncontrolledTooltip>
            </>}
          </Col>
        )}
      </Row>
    </FormProvider>

  )
};

export default SPRow;