import React from 'react';
import {Alert} from 'reactstrap';

// <Error message={error}/>
// ��������� ����� pre-line, ����� �������� �������� ����� \n � ���������� �� �������
// ��� ������������, ����� ������ ���������, � ����� ���� ����������� ������� ������
const Error = ({ message, ...rest }) => (
  <>
    {message && <Alert  {...rest} color="danger" style={{ whiteSpace: "pre-line" }}>{message}</Alert>}
  </>
);

export default Error;
