import { detailLinkFormatter, actionFormatter } from "helpers/formatter";
import {RouteNames} from "router/routes-names";

export const OwnerColumns = (itemActionMenu) => ([
  {
    name: 'id',
    label: 'ID',
  },
  {
    name: 'title',
    label: 'Название',
    formatter: detailLinkFormatter({detailLinkRoute: RouteNames.OWNERS_EDIT}),
  },
  {
    name: '',
    label: '',
    formatter: actionFormatter,
    align: 'right',
    formatExtraData: {
      menu: itemActionMenu
    }
  }
]);

export default OwnerColumns;