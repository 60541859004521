import React from 'react';
import { Label, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputLabel = ({
  label,
  id,
  tooltip
}) => {

  return (
    <>
      {!!label &&
        <Label for={id}>
          {label}
          { !!tooltip &&
            <>
              <FontAwesomeIcon icon="circle-question" className="ml-2 cursor-pointer" id={"tooltip"+id} />
              <UncontrolledTooltip placement="top" target={"tooltip"+id}>
                {tooltip}
              </UncontrolledTooltip>
            </>
          }
        </Label>
      }
    </>
  );
};

export default InputLabel;
