import {useEffect} from 'react';
import {emailFormatter, detailLinkFormatter, actionFormatter, dateFormatter, listItemFormatter} from "helpers/formatter";
import {RouteNames} from "router/routes-names";
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import * as yup from "yup";
import UserACL from 'components/acl/UserACL';
import ButtonIcon from 'components/common/ButtonIcon';
import {useHistory} from 'react-router-dom';


export const UserColumns = ( {itemActionsMenu, user={}} ) => {
  const {rolesGetList, authLoginAs, companiesGetList} = useActions()
  const { list: rolesList, isLoading: rolesLoading } = useSelector(state => state.roles);
  const { item: authItem } = useSelector(state => state.auth);
  const { list: companiesList, isLoading: companiesLoading } = useSelector(state => state.companies);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(rolesGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(companiesGetList, [])

  const router = useHistory()

  const enter = (userId) => {
    authLoginAs({userId})
    router.push('/')
  }

  const enterFormatter = (data, row, rowIndex, formatExtraData) => {
      return (
        <UserACL role={['developer']}>
          <ButtonIcon icon="arrow-right-to-bracket" key="btnEnter" onClick={ () => enter(row.id) } color="light" size="sm">
            Войти
          </ButtonIcon>
        </UserACL>
      );
  };

  return [
  {
    name: 'id',
    label: 'ID',
    type: 'number',
    validation: yup.string(),
  },
  {
    name: 'title',
    label: 'Имя',
    formatter: (text, user, index) =>
      user.access_role === 'developer' && authItem.access_role !== 'developer'
        ? text
        : detailLinkFormatter({detailLinkRoute: RouteNames.USERS_EDIT})(text, user, index),
    type: 'text',
    validation: yup.string().required('Введите имя'),
  },
  {
    name: 'access_role',
    label: 'Роль',
    type: 'select',
    options: rolesList,
    isLoading: rolesLoading,
    formatter: listItemFormatter("roles"),
  },
  {
    name: 'company_id',
    label: 'Компания',
    type: 'select',
    options: companiesList,
    isLoading: companiesLoading,
    formatter: listItemFormatter("companies"),
    validation: yup.number().typeError('Выберите компанию').required('Выберите компанию'),
  },
  {
    name: 'email',
    label: 'Email',
    formatter: emailFormatter,
    type: 'text',
    validation: yup.string().email('Email введен неверно').required('Введите Email'),
  },
  {
    name: 'password',
    label: 'Пароль',
    type: 'password',
    validation: yup.string().concat( user.id ?  null : yup.string().required('Введите пароль') ),
    // validation: yup.string().when('id', ([id], schema) => {
    //   return id > 0 ? schema : schema.required('Укажите пароль');
    // }),
    hidden: true,
  },
  {
    name: 'confirmPassword',
    label: 'Повторите пароль',
    type: 'password',
    validation: yup.string().oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
    hidden: true,
  },
  {
    name: 'reg_date',
    label: 'Дата регистрации',
    align: 'right',
    headerAlign: 'right',
    formatter: dateFormatter,
    type: 'date',
  },
  {
    name: 'enter',
    label: '',
    align: 'right',
    formatter: enterFormatter,
  },
  {
    name: '',
    label: '',
    formatter: (text, user, index) =>
      user.access_role === 'developer' && authItem.access_role !== 'developer' ? ''
      : actionFormatter(text, user, index, { menu: itemActionsMenu }),
    align: 'right',
  }
]};

export default UserColumns;