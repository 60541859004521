import React from 'react';
import {Card} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {useActions} from "hooks/useActions";
import orderBy from 'lodash/orderBy';
import SPEditorByRow from 'components/editor/SPEditorByRow';

const PayMethods = () => {
  const {paymethodsGetList, paymethodsSetList, paymethodsAdd, paymethodsRemove, paymethodsEdit } = useActions()

  const editorHeader = "Способы оплаты";

  const inputs = [
    {
      header: "Системное имя",
      type: "text",
      name: "name",
      // Можем установить системное имя только для новых способов, старые менять нельзя
      disabledEdit: true,
      required: {
        required: 'Введите системное имя'
      },
    },
    {
      header: "Название метода",
      type: "text",
      name: "title",
      required: {
        required: 'Введите название'
      },
    },
    {
      header:"Вес сортировки",
      type:"number",
      name:"sort_weight",
    },
  ];

  const sort = (list) => orderBy(list, ['sort_weight', 'title']);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={editorHeader} light={false} />
        <SPEditorByRow
          getList={paymethodsGetList}
          setList={paymethodsSetList}
          add={paymethodsAdd}
          remove={paymethodsRemove}
          edit={paymethodsEdit}
          sort={sort}
          namespace='paymethods'
          colsInputs={inputs}
        />
    </Card>
  );
};

export default PayMethods;







