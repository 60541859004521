import React, {useEffect, useState} from 'react';
import { FormGroup } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputDate = ({
  label,
  id,
  name,
  tooltip,
  defaultValue,
  placeholder,
  handleChange,
  className,
  value='',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;

  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    var date = moment(value, "YYYY-MM-DD", true);
    if(date.isValid()) {
      formMethods.setValue(name, date.format('DD.MM.YYYY'), { shouldDirty: true });
      setCurrentValue(date.format('DD.MM.YYYY'));
    } else {
      formMethods.setValue(name, '', { shouldDirty: true });
      setCurrentValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  formMethods.register(name);

  return (
    <FormGroup>
       <InputLabel id={id} label={label} tooltip={tooltip}/>
       <InputMask
        name={name}
        id={id}
        mask='99.99.9999'
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={classNames(className, 'form-control', { 'border-danger': errors[name]?.message })}
        // onchange должнен быть в конце
        onChange={ (event) => {
          const currentDate = event.target.value;
          setCurrentValue(currentDate);
          var date = moment(currentDate, "DD.MM.YYYY", true);
          if(date.isValid()) {
            formMethods.clearErrors([name]);
            // чтобы обновлялись данные в react-hook-form
            formMethods.setValue(name, date.format('YYYY-MM-DD'), { shouldDirty: true });
            handleChange({ name: name, value: date.format('YYYY-MM-DD') });
          }
          // если дата пустая
          else if(currentDate === '__.__.____') {
            formMethods.clearErrors([name]);
            // чтобы обновлялись данные в react-hook-form
            formMethods.setValue(name, '', { shouldDirty: true });
            handleChange({ name: name, value: '' });
          }
          // если в дате не осталось подчеркиваний, то есть она введена полностью, но она не соответствует валидной дате
          else if(currentDate.length === 10 && !currentDate.includes("_")) {
            formMethods.setError(name, {
              type: "manual",
              message: 'Дата введена неверно',
            });
          }
        }}
        value={currentValue}
        {...rest}
      />
      { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
    </FormGroup>
  );
}

export default FormInputDate;