import React, { Fragment, useEffect } from 'react';
import {OrderDetails} from "./components/OrderDetails";
import {useParams} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';

const OrdersEdit = () => {
  const params = useParams()
  const {ordersGetItem} = useActions()
  const {isGetItemLoading, item} = useSelector(state => state.orders);

  useEffect(() => {
      ordersGetItem(params.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
     <Fragment>
          { (isGetItemLoading || !item.id )? ( <Loader/> ) : ( <OrderDetails orderItem={item}/> ) }
     </Fragment>
  );
};

export default OrdersEdit;
