import React from 'react';
import TableEditor from "components/editor/TableEditor";
import {DriverColumns} from "./components/DriverColumns";
import {RouteNames} from "router/routes-names";
import {useHistory} from 'react-router-dom';
import {useActions} from "hooks/useActions";


const Drivers = () => {
  const namespace="drivers";
  const router = useHistory()
  const {driversRemove} = useActions()

  const withSelectedOptions = [
    {value: "delete", title: "Удалить", callback: driversRemove },
  ];

  const actionButtons = [
      {icon: "plus", title: "Добавить", link: RouteNames.DRIVERS_ADD}
  ];
  // список действий над выбранными элементами
  const itemActionsMenu = (row) => [
    {title: "Редактировать", callback: (row) => router.push(RouteNames.DRIVERS_EDIT(row.id)) },
    {title: "Удалить", callback: async (driver) => driversRemove([driver.id]), className: "text-danger" }
  ]

  return (
    <TableEditor
      title="Водители"
      columns={DriverColumns(itemActionsMenu)}
      withSelectedOptions={withSelectedOptions}
      actionButtons={actionButtons}
      namespace={namespace}
    />
  );
};

export default Drivers;