import React, {useEffect} from 'react';
import Layout from "layouts/Layout";
import {BrowserRouter} from "react-router-dom";
import {useActions} from "hooks/useActions";
import { CloseButton, Fade } from 'components/common/Toast';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import {toast, ToastContainer} from 'react-toastify';

function App() {

  const {authCheck, authSetIsLoading} = useActions();

  // флаг setIsAuthLoading должен проставляться именно тут, при загрузке компонента, а не внутри useEffect
  // Чтобы ничего не портилось, в этот компонент нельзя помещать какие-либо другие useEffect с зависимостью от других состояний
  // в случае необходимости их нужно вставлять в дочерние компоненты
  authSetIsLoading(true);

  useEffect(() => {
      const token = localStorage.getItem('token');
      console.log(token);
      if( token ) {
        console.log('check auth');
        authCheck();
      } else {
        authSetIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowserRouter>
        <Layout/>
      </BrowserRouter>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </>

    );

}

export default App;
