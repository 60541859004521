import React from 'react';
import TableEditorCellEdit from 'components/editor/TableEditor/TableEditorCellEdit';
import TableEditorFilter from 'components/editor/TableEditor/TableEditorFilter';
import {customFilter} from 'react-bootstrap-table2-filter';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// columns - изначально формат взят https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/getting-started.html
// в нем заменены ключи для удобства работы со спиком атрибутов в другом контексте
// dataField -> name, text -> label
// для фильтров указывается filterType - соответствует названию типа FormInput, filterLabel - надпись в поле, если отличается от названия
// в случае списков добавляются options и isLoading соответствующего списка

export const columnsTransform = ({columns, filterValue, resetFilters, setResetFilters}) => {

  // let allFilters = {};
  const tableColumns = columns.map( data => {
    let filter = data.filter;
    let filterRenderer;
    if(data.filterType) {
      filter = customFilter();
      filterRenderer = (onFilter, column) => {
        // allFilters[data.name] = '';
        // allFilters[data.name] = column;
        return <TableEditorFilter
          onFilter={onFilter}
          column={column}
          type={data.filterType}
          filterLabel={data.filterLabel}
          columnData={data}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
          initialFilterValues={filterValue}
        />
      }
    }


    let headerFormatter = undefined;
    if(data.tooltip) {
      headerFormatter = (column, colIndex) => {
        return(
               <>
                {column.text}
                <FontAwesomeIcon icon="circle-question" className="ml-2 cursor-pointer" id={"tooltip"+colIndex} />
                <UncontrolledTooltip placement="top" target={"tooltip"+colIndex}>
                  {data.tooltip}
                </UncontrolledTooltip>
              </>)
      }
    }

    return {
        dataField: data.name,
        headerClasses: 'border-0',
        text: data.label,
        classes: 'border-0 py-2 align-middle',
        sort: data.sort,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
          let validation = data.validation;
          // оборачиваем метод валидации, чтобы передать ему данные текущего ряда, так как валидация зависит от других данных в ряду
          if(data.validationWrap) {
            validation = data.validationWrap(row);
          }
          return (
            <TableEditorCellEdit
              item={row}
              placeholder={data.label}
              type={data.type}
              customType={data.customType}
              name={data.name}
              id={data.name}
              defaultValue={value}
              options={data.options}
              isLoading={data.isLoading}
              editorProps={editorProps}
              customData={data.customData}
              validation={validation}
            />

        )},
        formatter: data.formatter,
        filter: filter,
        filterRenderer: filterRenderer,
        hidden: data.hidden,
        editCellClasses: data.editCellClasses,
        formatExtraData: data.formatExtraData,
        headerAlign: data.headerAlign,
        align: data.align,
        editable: (data.disabledEdit ? false : true),
        headerFormatter: headerFormatter,
      }
  })

// console.log(tableColumns);
  return tableColumns;
}


const utils = { columnsTransform };

export default utils;
