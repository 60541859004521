import ApiService from "services/ApiService";
import AuthService from "services/AuthService";

const DriverService = new ApiService('drivers');
const OwnerService = new ApiService('owners');
const OrderService = new ApiService('orders');
const PayMethodService = new ApiService('dictionaries/paymethod');
const TruckService = new ApiService('trucks');
const UserService = new ApiService('users');
const RoleService = new ApiService('dictionaries/role');
const OrderStatusService = new ApiService('dictionaries/orderStatus');
const BrokerService = new ApiService('dictionaries/broker');
const PaytypeService = new ApiService('dictionaries/paytype');
const QuickpayTypeService = new ApiService('dictionaries/quickpayType');
const ParamService  = new ApiService('dictionaries/param');
const OrderContentService  = new ApiService('dictionaries/orderContent');
const CashflowService  = new ApiService('dictionaries/cashflow');
const FlowTypeService  = new ApiService('dictionaries/flowType');
const CashflowOperationTypeService  = new ApiService('dictionaries/cashflowOperationType');
const WalletService  = new ApiService('dictionaries/wallet');
const CompanyService  = new ApiService('dictionaries/company');
const VehicleService  = new ApiService('dictionaries/vehicle');
const VehicleTypeService  = new ApiService('dictionaries/vehicleType');

export {
  AuthService,
  DriverService,
  OwnerService,
  OrderService,
  PayMethodService,
  TruckService,
  UserService,
  RoleService,
  OrderStatusService,
  BrokerService,
  PaytypeService,
  QuickpayTypeService,
  ParamService,
  OrderContentService,
  CashflowService,
  FlowTypeService,
  CashflowOperationTypeService,
  WalletService,
  CompanyService,
  VehicleService,
  VehicleTypeService,
}