import React, {useEffect, useState} from 'react';
import { FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputText = ({
  label,
  id,
  name,
  defaultValue,
  tooltip,
  placeholder,
  className,
  handleChange,
  value='',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
    formMethods.setValue(name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { ref, onChange, onBlur } = formMethods.register(name);
  return (
    <FormGroup>
      <InputLabel id={id} label={label} tooltip={tooltip}/>
      <Input
        name={name}
        id={id}
        defaultValue={defaultValue}
        type="textarea"
        placeholder={placeholder}
        className={classNames(className, { 'border-danger': errors[name]?.message })}
        innerRef={ref}
        onBlur={onBlur}
        {...rest}
        // onchange ������� ���� ����� rest, �� ������, ���� ��� �� ���������
        onChange={ (event) => {
          onChange(event);
          setCurrentValue(event.target.value);
          handleChange({ name: name, value: event.target.value });
        }}
        value={currentValue}
      />
      { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
    </FormGroup>
  );
};

export default FormInputText;
