import React from 'react';
import {OrderDetails} from "./components/OrderDetails";

const OrdersAdd = () => {

  return (
    <OrderDetails />
  );
};

export default OrdersAdd;
