import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WorkLayout from './WorkLayout';
import Login from "pages/login/Login";
import {useSelector} from "react-redux";
import {RouteNames} from "router/routes-names";
import {isAuthSelector} from 'store/reducers/auth'
import { QueryParamProvider } from 'use-query-params';
import ConfirmAlert from 'components/common/Confirm';
import ConfirmContextProvider from "context/ConfirmContextProvider";

const LayoutRoutes = () => {
    const isAuth = useSelector(isAuthSelector);

    console.log('LayoutRoutes');

    return (
        <Router fallback={<span />}>
            <ConfirmContextProvider>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                        { !isAuth && <Route path={RouteNames.LOGIN} exact component={Login} /> }
                        <Route component={WorkLayout} />
                    </Switch>
                </QueryParamProvider>
                <ConfirmAlert />
            </ConfirmContextProvider>
        </Router>
    );
};

export default LayoutRoutes;
