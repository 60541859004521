import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';
import {Button, Form, FormGroup, Input, Spinner} from 'reactstrap';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Error from 'components/common/Error';

const LoginForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const {error, isLoading} = useSelector(state => state.auth);

  const {authLogin} = useActions()

  const handleSubmit = e => {
    e.preventDefault();
    authLogin({email, password})
    setEmail(email)
  }

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  return (
    <Form onSubmit={handleSubmit}>

      <Error message={error}/>

      <FormGroup>
        <Input
          placeholder='Email'
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        <Input
          placeholder='Пароль'
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      {/* <Row className="justify-content-between align-items-center"> */}
      {/*   <Col xs="auto"> */}
      {/*     <Link className="fs--1" to={`/authentication/${layout}/forget-password`}> */}
      {/*       Забыли пароль? */}
      {/*     </Link> */}
      {/*   </Col> */}
      {/* </Row> */}
      <FormGroup>

            <Button color="primary" block className="mt-3" disabled={isDisabled}>
                 {isLoading
                    ? <Spinner color="primary" children={false} />
                    : 'Войти'
                  }
           </Button>
      </FormGroup>
    </Form>
  );
};

export default LoginForm;
