import {createAsyncThunk} from "@reduxjs/toolkit";

export const baseObjectEditorInitial = {
  isAdded: false,
  isRemoved: false,
  isUpdated: false,
}

export const baseObjectEditorReducers = {
  setIsAdded(state, action) {
    state.isAdded = action.payload
  },
  setIsRemoved(state, action) {
    state.isRemoved = action.payload
  },
  setIsUpdated(state, action) {
    state.isUpdated = action.payload
  },
}

export const BaseObjectEditorActionCreators = (namespace, service) => {
  return {
    // getList(filter: {}, withPagination: true )
    getList: createAsyncThunk(namespace+'/getList', async (data={}, thunkAPI) => {
      console.log('get list '+namespace);
        const state = thunkAPI.getState()[namespace];
        try {
            const response = await service.getList(data.filter || state.filter, state.sortBy, state.sortOrder, state.page, (data.withPagination ? state.perPage : 0));
            return response.data;
        } catch (e) {
          console.log(e);
            return thunkAPI.rejectWithValue(e)
        }
    },
    {
      condition: (data={}, { getState, extra }) => {
        const state = getState()[namespace];
        // если в запросе передано в любом случае делать запрос, то не делаем проверки
        if(data.forceRequest) {
          return true;
        }
        // console.log('condition get list '+namespace+' state.isLoading: ' + state.isLoading);
        // если сейчас что-то грузится, то не грузим список
        if(state.isLoading) {
          return false
        }
        // console.log('condition get list '+namespace+' state.isListDirty: ' + state.isListDirty);
        // грузим списки только если изменились условия
        if (!state.isListDirty) {
          return false
        }
      }
    }),
    getItem: createAsyncThunk(namespace+'/getItem', async (itemId, thunkAPI) => {
        try {
            const response = await service.getItem(itemId);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }),
    add: createAsyncThunk(namespace+'/add', async (data={}, thunkAPI) => {
        try {
            const response = await service.add(data);
            return response.data;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    remove: createAsyncThunk(namespace+'/remove', async (arrayIds, thunkAPI) => {
        try {
            let successIds = [];
            let errors = '';
            await Promise.allSettled( arrayIds.map(id => service.remove(id)) )
              .then((results) => results.forEach((result) => {
                if(result.status === "fulfilled") {
                  successIds.push(result.value.data.id);
                } else {
                  errors = errors + result.reason.response.data.errors[0].message+"\n"
                }
              }));

            // await Promise.all(arrayIds.map(id => service.remove(id)) )
            return {successIds, errors};
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    edit: createAsyncThunk(namespace+'/edit', async ([itemId, item] , thunkAPI) => {
        try {
            const response = await service.edit(itemId, item);
            return [itemId, response.data];
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    })
  }
}

export const baseObjectEditorExtraReducers = (builder, actionCreators) => {
    builder
    .addCase(actionCreators.getList.fulfilled, (state, action) => {
      state.isAdded = false
      state.isRemoved = false
      state.isUpdated = false
      state.isListDirty = false
      state.list = action.payload.list
      state.totalSize = action.payload.total_size
    })
    .addCase(actionCreators.getItem.fulfilled, (state, action) => {
      const item = action.payload.item
      state.item = item
      state.isAdded = false
      state.isUpdated = false
      state.isRemoved = false
      // обновляем элемент в списке
      const index = state.list.findIndex(element => element.id === item.id);
      const newList = [...state.list];
      newList[index] = item;
      state.list = newList;
    })
    .addCase(actionCreators.add.fulfilled, (state, action) => {
      state.isAdded = true
      state.list.push(action.payload);
      state.item = action.payload
      state.totalSize++;
    })
    .addCase(actionCreators.remove.fulfilled, (state, action) => {
      state.isRemoved = true
      // удаляем удаленные записи из списка list
      action.payload.successIds.forEach(removedId => {
        const newList = state.list.filter(item => item.id !== +removedId)
        state.list = newList;
        state.totalSize--;
      })
    })
    .addCase(actionCreators.edit.fulfilled, (state, action) => {
      state.isUpdated = true
      const [itemId, newItem] = action.payload;
      const index = state.list.findIndex(item => item.id === itemId);
      const newList = [...state.list];
      newList[index] = newItem;
      state.item = newItem
      state.list = newList;
    })

    return builder;
}
