import React, { Fragment, useEffect } from 'react';
import {OwnerProfile} from "./components/OwnerProfile";
import {useParams} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';

const OwnersEdit = () => {
  const params = useParams()
  const {ownersGetItem, ownersSetError} = useActions()
  const {isLoading} = useSelector(state => state.owners);

  useEffect(() => {
      ownersGetItem(params.id)
      ownersSetError('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
     <Fragment>
          { isLoading ? ( <Loader/> ) : ( <OwnerProfile/> ) }
     </Fragment>
  );
};

export default OwnersEdit;
