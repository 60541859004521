import React, { Fragment } from 'react';
import {UserProfile} from "./components/UserProfile";

const UsersAdd = () => {

  return (
     <Fragment>
       <UserProfile />
     </Fragment>
  );
};

export default UsersAdd;
