import React, {useEffect, useState} from 'react';
import orderBy from 'lodash/orderBy';
import {Card} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SPEditorByRowTemporary from 'components/editor/SPEditorByRowTemporary';
import {useEditFormContext} from 'hooks/useEditFormContext';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";

const VehicleOwners = ({ vehicleId=0, owners=[] }) => {
  // название поля с содержимым заказа в форме
  const formFieldName = 'owners';
  const [currentOwners, setCurrentOwners] = useState(owners);
  const [totalShare, setTotalShare] = useState();
  const {formMethods, handleInputChange} = useEditFormContext();

  const {ownersGetList} = useActions()
  const { list: ownersList, isLoading: ownersLoading } = useSelector(state => state.owners);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(ownersGetList, [])


  // регистрируем поле owners в родительской форме, чтобы установить ему значение
  useEffect(() => {
    formMethods.register(formFieldName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  useEffect(() => {
    formMethods.setValue(formFieldName, currentOwners, { shouldDirty: true });
    handleInputChange({name: formFieldName, value: currentOwners});

    // сумма долей
    const totalSum = currentOwners.reduce((sum, currentItem) => { return sum + currentItem.ownership_share }, 0)
    setTotalShare(totalSum);
    console.log(totalShare);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentOwners)]);

  const editorHeader = "Собственники";
  const inputs = [
    {
      header: "Собственник",
      type: "select",
      name: "owner_id",
      options: ownersList,
      isLoading: ownersLoading,
      required: {
        required: 'Выберите собственника'
      },
    },
    {
      header: "Доля, %",
      type: "number",
      name: "ownership_share"
    },
    {
      header: "Дата покупки",
      type: "date",
      name: "purchase_date"
    },
    {
      header: "Дата продажи",
      type: "date",
      name: "sale_date"
    }
  ];
  const sort = (list) => {
    return orderBy(list, ['id'] );
  };

  const defaultValues = { order_id: vehicleId, ownership_share: (totalShare < 100 ? 100 - totalShare : 0 ) };

  return (
    <Card className="mb-3">
      <FalconCardHeader title={editorHeader} light={false} />
        <SPEditorByRowTemporary
          sort={sort}
          colsInputs={inputs}
          list={currentOwners}
          setList={setCurrentOwners}
          defaultValues={defaultValues}
        />
    </Card>
  );
};


export default VehicleOwners;
