import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FormInput from 'components/input/FormInput';
import {useEditFormContext} from 'hooks/useEditFormContext';

export const OrderDetailsFields = () => {

  const {getField, item: order} = useEditFormContext();

  const orderId = order.id;
  const isEditOrder = order.id;

  return (
    <Card className="mb-3">
      <FalconCardHeader title={isEditOrder ? 'Редактировать заказ '+orderId : 'Добавить заказ'} light={false}>
          {isEditOrder && 'Неделя ' + order.week + '/' + order.year }

    </FalconCardHeader>

      <CardBody className="border-top bg-light">
        <Row>
          <Col lg={4}>
            {isEditOrder ?
              <FormInput
                label='ID'
                name='id'
                type='text'
                disabled={true}
                value={orderId}
              />
             : getField('id')}
          </Col>
          <Col lg={8}>
            {getField('order_status_id')}
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="fs--1">
            {getField('drop_date')}
          </Col>
          <Col lg={8}>
            {getField('dispatcher_id')}
          </Col>
        </Row>
      </CardBody>
      <CardBody className="border-top">
        <Row>
          <Col lg={4}>
            {getField('broker_number')}
          </Col>
          <Col lg={8}>
            {getField('broker_id')}
          </Col>

        </Row>
        <Row>
          <Col lg={9}>
            {getField('driver_id')}
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            {getField('location_from')}
          </Col>
          <Col lg={4}>
            {getField('location_to')}
          </Col>
          <Col lg={4}>
            {getField('distance')}
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            {getField('order_sum')}
          </Col>
          <Col lg={4}>
            {getField('broker_fee')}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};



export default OrderDetailsFields;
