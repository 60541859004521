import React from 'react';
import {getEditFormContext} from 'hooks/useEditFormContext';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from "@hookform/resolvers/yup";
import FormInput from 'components/input/FormInput';
import {Form} from 'reactstrap';
import * as yup from "yup";


// columns - изначально формат взят https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/getting-started.html
// в нем заменены ключи для удобства работы со спиком атрибутов в другом контексте
// dataField -> name, text -> label
// для использования в форме редактирования добавлено:
// type - тип поля
// placeholder - placeholder, если отличается от названия
// validation - объект yup - yup.string().required('Заполните поле'),
// validationWrap - функция, которая получает весь row формы и возвращает объект yup для валидации (в случае, если в валидации участвуют другие поля из формы)
// в случае списков добавляются options и isLoading соответствующего списка + customType и customData при зависимых списках + namespace для selectCreatable

export const EditFormProvider = ({item, setItem, fields, onSubmit, children}) => {

  const EditFormContext = getEditFormContext();

  const validSchema = yup.object().shape(Object.assign({}, ...(
      fields.map(field => {
        if(field.name) {
          if(field.validation) {
            return { [field.name]: field.validation }
          } else if(field.validationWrap) {
            return { [field.name]: field.validationWrap(item) }
          }
        }
      } ))
  ));

  // console.log(validSchema);
  const formMethods = useForm({resolver: yupResolver(validSchema) });
  // const {handleSubmit} = formMethods;
  const {handleSubmit, reset} = formMethods;
  // const {isDirty, dirtyFields, touchedFields} = formMethods.formState;
  // console.log(isDirty);
  // console.log(touchedFields);

  // при собмите формы сбрасываем все параметры
  const onSubmitForm = (data, e) => {
    onSubmit(data, e);
    reset({});
  }

  // const watchAllFields = watch();
  // console.log(watchAllFields);
//   console.log(isDirty);
//   console.log(dirtyFields);
//   console.log(touchedFields);


  const handleInputChange = ({ value, name }) => {
      console.log({name, value});
      setItem({ ...item, [name]: value })
  };

  const getField = (name) => {
    const data = fields.find( (element) => {return element.name === name} );
    if(!data) {
      return '';
    }
    const placeholder = data.placeholder || data.label;
    return (<FormInput
            label={data.label}
            placeholder={placeholder}
            tooltip={data.tooltip}
            name={data.name}
            id={data.name}
            type={data.type}
            customType={data.customType}
            value={item[name]}
            handleChange={handleInputChange}
            customData={ {...data.customData, row: item} }
            options={data.options}
            isLoading={data.isLoading}
            namespace={data.namespace}
          />);
  }

  const getDisabledField = (name) => {
    const data = fields.find( (element) => {return element.name === name} );
    if(!data) {
      return '';
    }
    const placeholder = data.placeholder || data.label;
    return (<FormInput
            label={data.label}
            placeholder={placeholder}
            tooltip={data.tooltip}
            name={data.name}
            id={data.name}
            type={data.type}
            customType={data.customType}
            value={item[name]}
            handleChange={ () => {} }
            disabled={true}
          />);
  }

  const getValue = (name) => {
    return item[name];
  }

  const formEditor = {item, setItem, formMethods, handleInputChange, getField, getDisabledField, getValue};

  return (
    <EditFormContext.Provider value={formEditor}>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmitForm)}>
          {children}
        </Form>
      </FormProvider>
    </EditFormContext.Provider>
  )
};
