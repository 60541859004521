import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store";
import Main from './Main';
import 'helpers/initFA';

ReactDOM.render(
  <Main>
    <Provider store={store}>
       <App />
    </Provider>
  </Main>,

  document.getElementById('root')
);
