import {useEffect} from 'react';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import * as yup from "yup";

import { detailLinkFormatter, actionFormatter, listItemFormatter } from "helpers/formatter";
import {RouteNames} from "router/routes-names";

export const VehicleColumns = (itemActionMenu) => {

  const {vehicleTypesGetList} = useActions()
  const { list: vehicleTypesList, isLoading: vehicleTypesLoading } = useSelector(state => state.vehicleTypes);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(vehicleTypesGetList, [])


  return [
  {
    name: 'id',
    label: 'ID',
  },
  {
    name: 'title',
    label: 'Название',
    formatter: detailLinkFormatter({detailLinkRoute: RouteNames.VEHICLES_EDIT}),
  },
  {
    name: 'vehicle_type_id',
    label: 'Вид',
    type: 'select',
    formatter: listItemFormatter("vehicleTypes"),
    options: vehicleTypesList,
    isLoading: vehicleTypesLoading,
    validation: yup.string().required('Выберите вид ТС'),
  },
  {
    name: 'vin',
    label: 'VIN',
  },
  {
    name: 'model',
    label: 'Тип (марка)',
  },
  {
    name: 'cost',
    label: 'Стоимость',
    type: 'number',
  },
  {
    name: '',
    label: '',
    formatter: actionFormatter,
    align: 'right',
    formatExtraData: {
      menu: itemActionMenu
    }
  }
]

};

export default VehicleColumns;