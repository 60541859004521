import $api from "../http";

export default class ApiService {
    constructor(namespace) {
        this.namespace = namespace;
    }
    async getList(filter={}, sortBy='', sortOrder='', page=1, perPage=0) {
        // добавляем в строку параметры фильтрации, сортировки и пагинации
        // фильтры -> { id: {type: number, value: 5} }
        let queryParams={};
        if(Object.keys(filter).length) { queryParams['filter'] = filter }
        // сортировка -> sort_by=fio&sort_order=asc
        if(sortBy) { queryParams['sort_by'] = sortBy }
        if(sortOrder) { queryParams['sort_order'] = sortOrder }
        if(page !== 1) { queryParams['p'] = page }
        if(page !== 1) { queryParams['p'] = page }
        if(perPage) { queryParams['per_page'] = perPage }
console.log(queryParams);
        return await $api.get('/'+this.namespace+'/', {
            params: queryParams
        })
    }
    async getItem(id) {
        return await $api.get('/'+this.namespace+'/'+id)
    }
    async add(item) {
        return await $api.post('/'+this.namespace+'/', {item})
    }
    async remove(id) {
        return await $api.delete('/'+this.namespace+'/'+id)
    }
    async edit(id, item) {
        return await $api.put('/'+this.namespace+'/'+id, {item})
    }
    async upload(formData) {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const route = this.namespace.replace('dictionaries/','');
        return await $api.post('/'+route+'/upload', formData, config)
    }
}
