import React, { Fragment, useEffect, useState } from 'react';
import {OwnerProfile} from "./components/OwnerProfile";
import {useActions} from "hooks/useActions";
import Loader from 'components/common/Loader';

const OwnersAdd = () => {
  const {ownersSetItem, ownersSetError} = useActions()
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      ownersSetItem({})
      setIsLoading(false)
      ownersSetError('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
     <Fragment>
        { isLoading ? ( <Loader/> ) : ( <OwnerProfile/> ) }
     </Fragment>
  );
};

export default OwnersAdd;
