import React, { Fragment } from 'react';
import { CustomInput, FormGroup } from 'reactstrap';
import {useFormContext} from 'react-hook-form';

const FormInputRadio = ({
  label,
  id,
  name,
  tooltip,
  value,
  checked,
  handleChange,
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const { ref, onChange, onBlur } = formMethods.register(name);

  return (
    <FormGroup className="form-check">
      <CustomInput
        name={name}
        id={id}
        type="radio"
        defaultChecked={checked ? true : false}
        value={value}
        innerRef={ref}
        onBlur={onBlur}
        onChange={ (event) => {
          onChange(event);
          handleChange({ name: name, value: event.target.value });
        }}
        label={
          <Fragment>
            {label}
            { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
          </Fragment>
        }
        {...rest}
      />
    </FormGroup>
  );

};

export default FormInputRadio;
