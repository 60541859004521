import React, {useEffect, useState} from 'react';
import {CardBody, Col, Container, Row} from 'reactstrap';
import SPRow from './SPRow';
import {isIterableArray} from 'helpers/utils';
import ButtonIcon from 'components/common/ButtonIcon';
import sortBy from 'lodash/sortBy';
import Error from 'components/common/Error';
import uniqueId from 'lodash/uniqueId';

// Такой же редактор как SPEditorByRow, только все изменения идут не сразу на сервер через API,
// а сохраняются во временном списке, который должен потом обработаться родительским компонентом

const SPEditorByRowTemporary = ({
  list,
  setList,
  sort,
  colsInputs,
  defaultValues={},
  canEdit=1,
  canDelete=1,
  canAdd=1,
  deleteTooltip='Удалить',
  error,
}) => {

  const newId = -1;
  const [disableAdd, setDisableAdd] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  let sortedList = sort(list)
  // новую строку ставим всегда в конец
  sortedList = sortBy(sortedList, (row) => ( row.id === -1 ? 1 : -1 ) );

  // если в списке было что-то добавлено или обновлено, то сбрасываем флаги и удаляем временные строки в таблице
  useEffect(() => {
    if(isAdded || isUpdated) {
      setList(list.filter(mtd => mtd.id !== newId));
      setIsUpdated(false);
      setIsAdded(false);
      setDisableAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  // Add pay method
  const addRow = () => {
    // новую строку ставим всегда в конец
    setList([...list, {id: newId, ...defaultValues }]);
  }

  // Confirm adding method
  const confirmAddRow = (item) => {
    item.id = uniqueId('tempEditor_')
    setList([...list, item]);
    setIsAdded(true);
    return false;
  }

  const cancel = (rowId) => {
    if (rowId < 0) {
      setList(list.filter(row => row.id !== rowId));
    }
    setDisableAdd(false);
  }

  const remove = (arrayIds) => {
    arrayIds.forEach(removeId => {
      setList(list.filter(row => row.id !== removeId));
    });
  }

  const edit = ([itemId, item]) => {
    const index = list.findIndex(row => row.id === itemId);
    const newList = [...list];
    newList[index] = item;
    setList(newList);
    setIsUpdated(true);
  }

  return(
    <CardBody className="bg-light">
      <Error message={error}/>

      <Container className="bg-white">
      { isIterableArray(sortedList) &&
        <Row className="fs--1 px-1 pt-3 pb-1 font-weight-semi-bold">
          {colsInputs.map(column =>
            <Col {...column.colProps} className="py-1" key={column.name}>{column.header}</Col>
          )}
          <Col></Col>
        </Row>
      }
      { isIterableArray(sortedList) &&
        sortedList.map(row => (
          <SPRow
            item={row}
            key={row.id}
            handleCreate={confirmAddRow}
            handleRemove={remove}
            handleCancel={cancel}
            handleEdit={edit}
            colsInputs={colsInputs}
            canEdit={canEdit}
            canDelete={canDelete}
            deleteTooltip={deleteTooltip}
            isUpdated={isUpdated}
            isAdded={isAdded}
          />
        ))
      }
      { canAdd && <Row>
          <Col className="py-2">
            <ButtonIcon
              color="falcon-default"
              size="sm"
              icon="plus"
              onClick={()=>{addRow(); setDisableAdd(true)}}
              disabled={disableAdd}>
                Добавить
            </ButtonIcon>
          </Col>
      </Row> }

    </Container>
    <div style={{display: 'flex'}} className="pt-3">

    </div>
  </CardBody>
)

};

export default SPEditorByRowTemporary;
