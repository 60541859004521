import React, {useEffect} from 'react';
import LayoutRoutes from "./LayoutRoutes";
import {useSelector} from "react-redux";
import Loader from 'components/common/Loader';
import {useActions} from "hooks/useActions";
import {toast} from 'react-toastify';

const Layout = () => {
  const {setSystemError} = useActions();
  const {systemError} = useSelector(state => state.system);
  const isAuthLoading = useSelector(state => state.auth.isLoading);

  useEffect(() => {
    if (systemError.message === '') return;

    toast.error(<span className="text-white">{systemError.name}: {systemError.message}</span>);
    setSystemError('', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemError]);


  return (
      <>
          { isAuthLoading ? ( <Loader/> ) : ( <LayoutRoutes/> ) }
      </>


  );
};

export default Layout;
