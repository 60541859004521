import React, { useEffect } from 'react';
import { listItemFormatter, hideZeroFormatter } from "helpers/formatter";
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import CashflowSum from "components/list-items/CashflowSum";
import {OrderService} from "services/index";
import * as yup from "yup";

const sumFormatter = (data, row, rowIndex, formatExtraData) => {
    return (
      <CashflowSum sum={data} flowType={row.flow_type}/>
    );
};


const weekFormatter = (data, row, rowIndex, formalabelraData) => {
  let year = row.year.toString();
  if(year.length === 4) {
    year = year.substr(2,2)
  }
  return (
    <>
      {row.week}/{year}
    </>
  );
};

export const CashflowColumns = (itemActionMenu) => {

  const {flowTypesGetList, cashflowOperationTypeGetList, walletGetList, paymethodsGetList } = useActions()
  const { list: flowTypesList, isLoading: flowTypesLoading } = useSelector(state => state.flowTypes);
  const { list: cashflowOperationTypeList, isLoading: cashflowOperationTypeLoading } = useSelector(state => state.cashflowOperationType);
  const { list: walletList, isLoading: walletLoading } = useSelector(state => state.wallet);
  const { list: paymethodsList, isLoading: paymethodsLoading } = useSelector(state => state.paymethods);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(flowTypesGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cashflowOperationTypeGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(walletGetList, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(paymethodsGetList, [])

  // Если введен номер заказа, проверяем его существование в базе
  // Если номер не введен, то проверяем статью. Если оплата по договору, то номер заказа обязательный
  const validateOrderId = (row) => {
    return yup.string().test('validateOrderEmpty', 'Введите номер заказа', (orderId) => {
        // TODO: сделать систему правил для типов, чтобы не сравнивать id - !Повторяется в CashflowAddForm
          if(row.cashflow_operation_type_id === "10" && !orderId) {
            return false;
          }
          return true;
    }).test('validateOrderId', 'Неверный номер заказа', async (orderId) => {
        if(orderId) {
          try {
            // тут нельзя использовать экшены, только прямые запросы
            await OrderService.getItem(orderId);
            return true;
          } catch (e) {
            return false;
          }
        } else {
          return true;
        }
      }
    )
  }


  // Обазательный для оплаты по договору
  const validatePaymethodId = (row) => {
    return yup.string().test('validatePaymethodId', 'Введите тип платежа', (value) => {
      // TODO: сделать систему правил для типов, чтобы не сравнивать id - !Повторяется в CashflowAddForm
      if(row.cashflow_operation_type_id === "10" && !value) {
        return false;
      }
      return true;
    })
  }

  const columns = [
    {
      label: "ID",
      type: "text",
      name: "id",
      disabledEdit: true,
      classes: "col-auto",
      filterType: 'number',
      sort: true,
    },
    {
      label: "Нед.",
      type: "text",
      name: "week",
      disabledEdit: true,
      filterType: 'number',
      sort: true,
      formatter: weekFormatter,
    },
    {
      label: "Операция",
      type: "select",
      name: "flow_type",
      validation: yup.string().required('Выберите тип операции'),
      options: flowTypesList,
      isLoading: flowTypesLoading,
      formatter: listItemFormatter("flowTypes"),
      filterType: 'select',
      sort: true,
    },
    {
      label: "Статья",
      type: "select",
      customType: "selectFiltered",
      name: "cashflow_operation_type_id",
      validation: yup.string().required('Выберите статью'),
      options: cashflowOperationTypeList,
      isLoading: cashflowOperationTypeLoading,
      customData: { filter: "flow_type"},
      filterType: 'select',
      formatter: listItemFormatter("cashflowOperationType"),
      sort: true,
    },
    {
      label: "Дата",
      type: "dateCalendar",
      name: "date",
      validation: yup.date().required('Выберите дату'),
      filterType: 'date',
      // hidden: true,
      sort: true,
    },
    {
      label: "Кошелек",
      type: "select",
      name: "wallet_id",
      validation: yup.string().required('Выберите кошелек'),
      options: walletList,
      isLoading: walletLoading,
      formatter: listItemFormatter("wallet"),
      filterType: 'selectMulti',
      sort: true,
    },
    {
      label: "Контрагент",
      type: "select",
      name: "wallet_id_to",
      options: walletList,
      isLoading: walletLoading,
      formatter: listItemFormatter("wallet"),
      filterType: 'selectMulti',
      sort: true,
    },
    {
      label: "Сумма",
      type: "number",
      name: "sum",
      validation: yup.number().typeError('Введите сумму').required('Введите сумму').positive('Сумма должна быть положительным числом'),
      formatter: sumFormatter,
      filterType: 'number',
      sort: true,
    },
    {
      label: "Заказ",
      type: "number",
      name: "order_id",
      validationWrap: validateOrderId,
      formatter: hideZeroFormatter,
      // formatter: detailLinkFormatter({detailLinkRoute: RouteNames.ORDERS_EDIT, target: 'blank'}),
      filterType: 'number',
      sort: true,
    },
    {
      label: "Тип платежа",
      type: "select",
      name: "paymethod_id",
      validationWrap: validatePaymethodId,
      options: paymethodsList,
      isLoading: paymethodsLoading,
      formatter: listItemFormatter("paymethods"),
      filterType: 'selectMulti',
      sort: true,
    },
    {
      label: "Комментарий",
      type: "textarea",
      name: "comment",
      filterType: 'text',
      editCellClasses: 'column-textarea',
      sort: true,
    },
  ];

  return columns;
};

export default CashflowColumns;