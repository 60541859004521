import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody,  Col, Row} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {RouteNames} from "router/routes-names";
import {Link} from 'react-router-dom';
import Error from 'components/common/Error';
import {UserColumns} from "./UserColumns";
import {EditFormProvider} from 'components/editor/EditFormProvider';

import {useEditFormContext} from 'hooks/useEditFormContext';


const ProfileForm = () => {

  const {getField, item} = useEditFormContext();
  const {error} = useSelector(state => state.users);
  const isEditProfile = item.id
  const isAddProfile = !item.id

  return (
    <>
    <FalconCardHeader title={isEditProfile ? 'Редактировать пользователя' : 'Добавить пользователя'} light={false} />
      <CardBody className="bg-light">
        <Row>
          <Col lg={6}>
            {getField('title')}
          </Col>
          <Col lg={6}>
            {getField('email')}
          </Col>
          <Col lg={6}>
            {getField('password')}
          </Col>
          <Col lg={6}>
            {getField('confirmPassword')}
          </Col>
          <Col lg={6}>
            {getField('access_role')}
          </Col>
          <Col lg={6}>
            {getField('company_id')}
          </Col>
          <Col lg={12}>
            <Error message={error}/>
          </Col>
          <Col tag={Flex} xs={12} justify="end">
            <Button color="primary" type="submit">
              {isAddProfile ? 'Добавить' : 'Изменить'}
            </Button>
            <Button color="secondary" tag={Link} to={RouteNames.USERS} className="ml-2">
              Отмена
            </Button>
          </Col>
        </Row>
      </CardBody>
    </>
  )
}


export const UserProfile =  ({userItem={}}) => {


  const { isAdded, isUpdated } = useSelector(state => state.users);
  const { usersAdd, usersEdit, usersSetIsAdded, usersSetIsUpdated } = useActions()
  const router = useHistory()

  const userId = userItem.id
  const [user, setUser] = useState(userItem);

  const allColumnsList = UserColumns({user});

  const onSubmit = (data, e) => {
    const sendData = {...user };
    delete sendData.id;
    delete sendData.reg_date;
    delete sendData.confirmPassword;
    if(userId) {
      usersEdit([userId, sendData]);
    } else {
      usersAdd(sendData);
    }
  };


  useEffect(() => {
    if(isAdded === true || isUpdated === true) {
      usersSetIsAdded(false);
      usersSetIsUpdated(false);
      router.push(RouteNames.USERS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated])

  return (
    <Card className="mb-3">
      <EditFormProvider
        fields={allColumnsList}
        onSubmit={onSubmit}
        item={user}
        setItem={setUser}
      >
        <ProfileForm/>
      </EditFormProvider>
    </Card>
  );
};

export default UserProfile;