import React, { Fragment, useState, useEffect } from 'react';
import {CardBody} from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {useSelector} from "react-redux";
import {useObjectActions} from "hooks/useActions";
import Loader from 'components/common/Loader';
import {isIterableArray} from 'helpers/utils';
import filterFactory from 'react-bootstrap-table2-filter';
import TableEditorTablePagination from './TableEditorTablePagination';
import {columnsTransform} from './tableEditorUtils';
import orderBy from 'lodash/orderBy';
// работа с react-bootstrap-table-next
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
// https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/about.html
const TableEditorTable = ({ table, data, columns, onRowClick, setIsSelected, namespace, isEditable, query, setQuery }) => {
  const {setPage, setFilter, setSort, setIsAdded, edit} = useObjectActions(namespace);
  const {perPage, totalSize, page: currentPage, isLoading, sortBy, sortOrder, isAdded, filter: filterValue} = useSelector(state => state[namespace]);

  const [tableData, setTableData] = useState(data);
  const [tableColumns, setTableColumns] = useState();
  // сохраняем старые данные, чтобы потом отмечать изменения
  const [oldTableData, setOldTableData] = useState([]);
  const [isFiltered, setIsFiltered] = useState();
  const [resetFilters, setResetFilters] = useState();
  const sort = (data) => {
    if(sortBy) {
      return orderBy(data, [sortBy], [sortOrder]);
    } else {
      return data;
    }
  };
  // преобразуем наш формат списка колонок в формат, необходимый для react-bootstrap-table-next
  useEffect(() => {
    setTableColumns( columnsTransform({columns, filterValue, resetFilters, setResetFilters}) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  // преобразуем наш формат списка колонок в формат, необходимый для react-bootstrap-table-next
  useEffect(() => {
    let filtersDefined = false;
    if(filterValue) {
      // смотрим, определен ли хотя бы один фильтр
      Object.keys(filterValue).forEach( (key) => {
              if(
                   (filterValue[key].type === 'selectMulti' && isIterableArray(filterValue[key].value) )
                || (filterValue[key].type !== 'selectMulti' && filterValue[key].value )
                || filterValue[key].to
                || filterValue[key].from
                ) {
                filtersDefined = true;
              }
            })
    }
    setIsFiltered(filtersDefined);

    if(filtersDefined) {
      // записываем параметры в url
      setQuery({ filter: filterValue });
    } else {
      setQuery({ filter: undefined });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValue)]);

  // записвыаем параметры в URL
  useEffect(() => {
    let newQuery = {};
    if(query.sortOrder !== sortBy) newQuery.sortBy = sortBy
    if(query.sortOrder !== sortOrder) newQuery.sortOrder = sortOrder
    if(query.p !== currentPage) newQuery.p = currentPage

    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortBy, sortOrder]);

  useEffect(() => {
    if(table.current) {
      table.current.selectionContext.selected = [];
    }
    setIsSelected(0);
    setTableData(data);
    setResetFilters(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if(isAdded) {
      // выполняем сортировку, чтобы новые добавленные данные становились на текущей странице в заданном порядке
      // при этом могут перепутываться данные, которые до этого были на странице, потому что серверная сортировка может работать по-другому
      setTableData(sort(data));
      setIsAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded]);

  useEffect(() => {
    // если началась загрузка, сохраняем старые данные в таблице
    if(isLoading) {
      setOldTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);


  const onSelect = () => {
    setImmediate(() => {
      setIsSelected(!!table.current?.selectionContext.selected.length);
    });
  };


  const rowStyle = (row, rowIndex) => {
    if(onRowClick) {
      return { cursor: 'pointer' };
    }
  };
  // подсвечиваем новые и измененные строки в таблице
  const rowClasses = (row, rowIndex) => {
    // строка новая, если она не встречалась в старой таблице
    const isRowNew = isIterableArray(oldTableData) && oldTableData.filter( (element) => element.id === row.id ).length === 0;
    // строка отредактированная, если она было обновление и это обновленная запись
    const isRowEdited = isIterableArray(oldTableData) && JSON.stringify(oldTableData.find(x => x.id === row.id)) !== JSON.stringify(row);
    if(isRowNew || isRowEdited) {
      return 'btn-reveal-trigger border-top border-200 flash';
    }
    return 'btn-reveal-trigger border-top border-200'
  };

  let rowEvents = {};
  if(onRowClick) {
    rowEvents.onClick = onRowClick;
  }
  const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        {...rest}
        onChange={() => {}}
        ref={input => {
          if (input) input.indeterminate = indeterminate;
        }}
      />
      <label className="custom-control-label" />
    </div>
  );


  const selectRowCheckbox = (onSelect) => ({
    mode: 'checkbox',
    columnClasses: 'py-2 align-middle',
    clickToSelect: false,
    selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
    selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
    headerColumnStyle: { border: 0, verticalAlign: 'middle' },
    selectColumnStyle: { border: 0, verticalAlign: 'middle' },
    onSelect: onSelect,
    onSelectAll: onSelect,
    classes: 'bg-200'
  });


  const handleCellEdit = ({ oldTable, cellEdit: { rowId, dataField, newValue } }) => {
    // чтобы убрать редактируемое поле, нужно обязательно обновить состояние таблицы
    // просто поставить старое значение через setList не приводит к результату
    // приходится делать обновление поля на старое значение
    console.log({ rowId, dataField, newValue });
    // console.log(oldTable);
    // по нажатию на значок отмены, нам приходит значение saveOldValue (в TableEditorCellEdit)
    if(newValue === 'saveOldValue') {
      // делаем искусственную пересборку данных, ничего в них не меняя, иначе таблица не обновляется
      const result = oldTable.map((row) => {
        if (row.id === rowId) {
          return { ...row };
        }
        return row;
      });
      setTableData(result);
    }
    // Обновляем данные в базе, таблица обновляется сама
    else {
      // вставляем ... пока данные обновляются
      const result = oldTable.map((row) => {
        if (row.id === rowId) {
          const newRow = { ...row };
          newRow[dataField] = '...';
          return newRow;
        }
        return row;
      });
      setTableData(result);

      edit([rowId, { [dataField]: newValue}]);
    }
  }

  const handleFilterChange = ({filters}) => {
    // преобразуем формат table-next { id: { filterVal: { type: number, value: 50 }, comparator: ...  }
    // в свой формат { id: { type: number, value: 50 } } - оставляем только значения
    filters =  Object.assign({}, ...(Object.keys(filters).map( (key) => {
                    // если есть какие-либо ключи
                    if(!!Object.keys(filters[key].filterVal).length) {
                        return { [key]: filters[key].filterVal }
                    }
          })
    ));

    console.log(filters);
    setFilter(filters);
  }
  const handleSortChange = ({sortField, sortOrder}) => {
    setSort({ sortBy: sortField, sortOrder: sortOrder })
  }

  const clearFilter = () => {
    setResetFilters(true);
    // тут есть возможный глюк
    // здесь setFilter вызывает запрос к API для получения всего списка
    // дальше после нажатия кнопки очистки, в каждом поисковом атрибуте вызывается onFilter(undefined), что приводит к setFilter для каждого поискового атрибута
    // запрос делается один раз, потому что он выполняется медленнее, чем все onFilter в атрибутах
    // если предположить, что запрос пройдет очень быстро, то может быть несколько запросов к API при очистке фильтров
    setFilter({});
    setPage(1);
  }

  const handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, data: oldTable, cellEdit }) => {
    console.log(type);
    if(type === 'cellEdit') {
      handleCellEdit({oldTable, cellEdit});
    }
    // console.log(oldTable);
    if(currentPage !== page) {
      // чтобы появлялась крутилка, пока грузятся новые данные
      setTableData([]);
      setPage(page);
    }
    if(type === 'filter') {
      handleFilterChange({filters});
    }
    if(type === 'sort') {
      handleSortChange({sortField, sortOrder});
    }
  }

  const cellEdit = isEditable ? cellEditFactory({
    mode: 'click',
    blurToSave: true
  }) : {};


  const paginationOptions = {
    page: currentPage,
    custom: true,
    sizePerPage: perPage,
    totalSize: totalSize,
  };

  const noDataIndication = () => {
    if(isLoading) {
      return <Loader />
    } else {
      return 'Записей не найдено'
    }
  }

  return (
    <>
      <CardBody className="p-0">
                {isIterableArray(tableColumns) &&
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Fragment>
                <TableEditorTablePagination
                    paginationProps={paginationProps}
                    isFiltered={isFiltered}
                    clearFilter={clearFilter}
                />
                <div className="table-responsive">
                  <BootstrapTable
                    ref={table}
                    isKey={true}
                    bootstrap4
                    remote
                    hover
                    keyField="id"
                    noDataIndication={noDataIndication}
                    data={tableData}
                    columns={tableColumns}
                    rowEvents={rowEvents}
                    rowStyle={rowStyle}
                    selectRow={selectRowCheckbox(onSelect)}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    style={{ overflow: "visible" }}
                    rowClasses={rowClasses}
                    headerClasses="bg-200 text-900 border-y border-200"
                    cellEdit={ cellEdit }
                    onTableChange={ handleTableChange }
                    filter={ filterFactory() }
                    filterPosition="top"
                    {...paginationTableProps}
                  />
                </div>
                <TableEditorTablePagination
                    paginationProps={paginationProps}
                    isFiltered={isFiltered}
                    clearFilter={clearFilter}
                />
              </Fragment>
            );
          }}
        </PaginationProvider>
                }
      </CardBody>

    </>
  );

}


TableEditorTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sort: PropTypes.func,
};

TableEditorTable.defaultProps = {
  data: [],
  sort: (data) => { return data },
};

export default TableEditorTable;