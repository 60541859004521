import React from 'react';
import {Link} from 'react-router-dom';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ListItem from "components/list-items/ListItem";
import moment from 'moment';

export const emailFormatter = (email) => {
   return <a href={`mailto:${email}`}>{email}</a>
}
export const phoneFormatter = (phone) => {
   return <a href={`tel:${phone}`}>{phone}</a>
}

// Если значение равно 0, то будет пустая строка
export const hideZeroFormatter = (value) => {
   if(value) {
     return value;
   }
   return '';
}

export const detailLinkFormatter = ({detailLinkRoute, target="self"}) => {
  return (data, row, rowIndex, formatExtraData) => {
    const to = detailLinkRoute(row.id)
    return (
      <Link to={to} target={"_"+target}>
        <h5 className="mb-0 fs--1">{data}</h5>
      </Link>
    );
  };
};


export const dateFormatter = (date) => {
  var dateMoment = moment(date);
  if(dateMoment.isValid()) {
    return dateMoment.format('DD.MM.YYYY');
  }
  return '';
};

export const dateTimeFormatter = (date) => {
  var dateMoment = moment(date);
  if(dateMoment.isValid()) {
    return dateMoment.format('DD.MM.YYYY HH:mm');
  }
  return '';
};


export const listItemFormatter = (namespace) => {
  return (data, row, rowIndex, formatExtraData) => {
    return (
      <ListItem namespace={namespace} itemId={data}/>
    );
  }
};

export const actionFormatter = (data, row, rowIndex, formatExtraData) => {
  return (
      <UncontrolledDropdown>
       <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
         <FontAwesomeIcon icon="ellipsis" className="fs--1" />
       </DropdownToggle>
       <DropdownMenu right className="border py-2">
       {formatExtraData.menu(row).map((menu, i) =>
         <DropdownItem key={i} onClick={() => menu.callback(row)} className={menu.className} >
               {menu.title}
         </DropdownItem>
         )}
       </DropdownMenu>
     </UncontrolledDropdown>
  );
};


export const selectTypeFormatter = (data, row, rowIndex, formatExtraData) => {

  return (
      <UncontrolledDropdown>
       <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
        {/* какое подпись формата лучше????*/}
        {/* {row.type} */}
         {formatExtraData.menu.find(menu => menu.value===row.type).title}
       </DropdownToggle>
       <DropdownMenu right className="border py-2">
       {formatExtraData.menu.map(menu =>
         <DropdownItem onClick={() => formatExtraData.changeType(menu.value, rowIndex)} className={menu.className} >
              {menu.title}
         </DropdownItem>
         )}
       </DropdownMenu>
     </UncontrolledDropdown>
  );
};

const formatter = { emailFormatter, phoneFormatter, detailLinkFormatter, actionFormatter,  selectTypeFormatter};

export default formatter;
