import React from 'react';
import FormInputPhone from './input-types/Phone';
import FormInputDate from './input-types/Date';
import FormInputDateCalendar from './input-types/DateCalendar';
import FormInputCheckbox from './input-types/Checkbox';
import FormInputRadio from './input-types/Radio';
import FormInputSelect from './input-types/Select';
import FormInputSelectCreatable from './input-types/SelectCreatable';
import FormInputSelectMulti from './input-types/SelectMulti';
import FormInputText from './input-types/Text';
import FormInputTextarea from './input-types/Textarea';
import FormInputFile from './input-types/File';

// поля формы должны быть в контексте <FormProvider {...formMethods}> из плагина react-hook-form
// из него будут браться методы для валидации и регистрации полей

const FormInput = ({
  label,
  id,
  name,
  placeholder,
  tooltip,
  className,
  type,
  checked,
  value,
  options = [], // варианты для select
  isLoading, // признак загрузки списка в случае с select
  handleChange,
  namespace, // имя редюсера, отвечающего за список, в который добавляется запись
  customType, // дополнительный подтип, сейчас сделан для select - selectFiltered
  customData, // дополнительные данные для подтипа
  ...rest
}) => {

  if (type === 'phone') {
    return (
      <FormInputPhone
        label={label}
        id={id}
        name={name}
        placeholder={placeholder}
        tooltip={tooltip}
        handleChange={handleChange}
        className={className}
        value={value}
        {...rest}
        />
    );
  }
  if (type === 'date') {
    return (
      <FormInputDate
        label={label}
        id={id}
        name={name}
        placeholder={placeholder}
        tooltip={tooltip}
        handleChange={handleChange}
        className={className}
        value={value}
        {...rest}
        />
    );
  }
  if (type === 'dateCalendar') {
    return (
      <FormInputDateCalendar
        label={label}
        id={id}
        name={name}
        placeholder={placeholder}
        tooltip={tooltip}
        handleChange={handleChange}
        className={className}
        value={value}
        {...rest}
        />
    );
  }
  if (type === 'checkbox') {
    return (
      <FormInputCheckbox
        label={label}
        id={id}
        name={name}
        handleChange={handleChange}
        tooltip={tooltip}
        className={className}
        value={value}
        {...rest}
        />
    );
  }
  if (type === 'radio') {
    return (
      <FormInputRadio
        name={name}
        id={id}
        checked={checked}
        label={label}
        tooltip={tooltip}
        handleChange={handleChange}
        className={className}
        value={value}
        {...rest}
      />
    );
  }
  if (type === 'select') {
    return (
      <FormInputSelect
        name={name}
        id={id}
        label={label}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        tooltip={tooltip}
        handleChange={handleChange}
        customType={customType}
        customData={customData}
        className={className}
        value={value}
        {...rest}
      />
    );
  }
  if (type === 'selectCreatable') {
    return (
      <FormInputSelectCreatable
        name={name}
        id={id}
        label={label}
        options={options}
        placeholder={placeholder}
        tooltip={tooltip}
        isLoading={isLoading}
        handleChange={handleChange}
        namespace={namespace}
        className={className}
        value={value}
        {...rest}
      />
    );
  }
  if (type === 'selectMulti') {
    return (
      <FormInputSelectMulti
        name={name}
        id={id}
        label={label}
        options={options}
        placeholder={placeholder}
        tooltip={tooltip}
        isLoading={isLoading}
        handleChange={handleChange}
        className={className}
        value={value}
        {...rest}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <FormInputTextarea
        name={name}
        id={id}
        placeholder={placeholder}
        tooltip={tooltip}
        value={value}
        label={label}
        handleChange={handleChange}
        className={className}
        {...rest}
      />
    );
  }

  if (type === 'file') {
    return (
      <FormInputFile
        name={name}
        id={id}
        placeholder={placeholder}
        tooltip={tooltip}
        value={value}
        label={label}
        handleChange={handleChange}
        className={className}
        {...rest}
      />
    );
  }


  return (
    <FormInputText
      name={name}
      id={id}
      placeholder={placeholder}
      tooltip={tooltip}
      value={value}
      label={label}
      type={type}
      handleChange={handleChange}
      className={className}
      {...rest}
    />
  );
};

export default FormInput;
