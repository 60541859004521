import React, { Fragment, useEffect, useState } from 'react';
import { CustomInput, FormGroup, Spinner } from 'reactstrap';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputSelect = ({
  label,
  id,
  name,
  placeholder,
  tooltip,
  className,
  handleChange,
  options = [], // варианты для select
  isLoading, // признак загрузки списка в случае с select
  customType,
  customData={},
  value='',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const [selectOptions, setSelectOptions] = useState(options);
  const [currentValue, setCurrentValue] = useState(value);

      // Фильтрация
      // customData: { filter: "flow_type"},
  useEffect(() => {
      if(customType === "selectFiltered" && customData.filter ){
        const filteredOptions = options.filter(option => {
          if (option[customData.filter] === customData.row[customData.filter]) {
            return true;
          } else {
            return false;
          }
        })
        setSelectOptions(filteredOptions);
      }
      else {
        setSelectOptions(options);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, customType, customData]);

  // установка начального значения в react-hook-form
  useEffect(() => {
      setCurrentValue(value);
      formMethods.setValue(name, value, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { ref, onChange, onBlur } = formMethods.register(name);

  return ( <>
      {
        ( isLoading ) ?
          (  <Spinner size="sm" color="primary" style={{marginLeft: 10}}/> ) :
          (
            <FormGroup className="fs--1">
              <InputLabel id={id} label={label} tooltip={tooltip}/>
              <CustomInput
                name={name}
                id={id}
                placeholder={placeholder}
                type="select"
                className={classNames(className, { 'border-danger': errors[name]?.message }, { 'text-400': !value } )}
                innerRef={ref}
                onBlur={onBlur}
                onChange={ (event) => {
                  onChange(event);
                  setCurrentValue(event.target.value);
                  handleChange({ name: name, value: event.target.value });
                }}
                value={currentValue}
                {...rest}
              >
                { placeholder && <option value="" className="text-400">{placeholder}</option> }
                {selectOptions.map((option, index) => (
                  <option value={option.id} className="text-900" key={option.id}>
                    {option.title}
                  </option>
                ))}
              </CustomInput>
              { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
            </FormGroup>
        )
      }
      </>
    );
};


export default FormInputSelect;

