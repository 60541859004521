
export const baseFiltersInitial = {
  filter: {},
  sortBy: '',
  sortOrder: 'asc',
}

export const baseFiltersReducers = {
  // setFilter({
    // id: { type: 'number', value: 50, from: 20, to: 70 }
    // date: { type: 'date', value: '2021-04-02', from: '2021-01-01', to: '2021-12-31' }
    // status_id: { type: 'multiselect', value: [1, 2, 3] }
    // paymethod_id: { type: 'select', value: 5 }
  // })
  setFilter(state, action) {
    state.filter = action.payload;
    console.log(action.payload)
    // state.filter = { ...state.filter, [action.payload.name]: action.payload.value }
    state.isListDirty = true
  },
  // setSort({ sortBy: 'title', sortOrder: 'asc' })
  setSort(state, action) {
    state.sortBy = action.payload.sortBy;
    state.sortOrder = action.payload.sortOrder;
    state.isListDirty = true
  },
}

