import React from 'react';
import { useListItem } from "hooks/useListItem";
import { Badge } from 'reactstrap';

const OrderStatusBadge = ({id}) => {
  const {getItem} = useListItem('orderStatuses');
  const item = getItem(id);

  return (
    <>
      { item && <Badge color={`soft-${item.color}`} key={item.id} className="fs--1" pill>
        {item.title}
      </Badge> }
    </>
  );
};

export default OrderStatusBadge;
