import React from 'react';
import {VehicleDetails} from "./components/VehicleDetails";

const VehiclesAdd = () => {

  return (
    <VehicleDetails />
  );
};

export default VehiclesAdd;
