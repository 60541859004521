import {useSelector} from "react-redux";
import {useObjectActions} from "hooks/useActions";

export const useTrucks = () => {
  const {list} = useSelector(state => state.trucks);
  const {getList} = useObjectActions('trucks');
  getList();

  // количество грузовиков у владельца
  const trucksCount = ({ownerId}) => {
      const found = list.filter( truck => truck.owner_id === ownerId );
      return found.length;
  }

  const getTruckByVin = ({vin}) => {
      const foundTruck = list.find(row => row.vin === vin) || {};
      return foundTruck;
  }


  return {trucksCount, getTruckByVin};
}