import React, { Fragment, useEffect, useState } from 'react';
import { CustomInput, FormGroup } from 'reactstrap';
import {useFormContext} from 'react-hook-form';

const FormInputCheckbox = ({
  label,
  id,
  name,
  tooltip,
  value,
  handleChange,
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const { ref, onChange, onBlur } = formMethods.register(name);

  const [check, setCheck] = useState(value ? true : false);

  useEffect(() => {
    setCheck(value ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormGroup className="form-check">
      <CustomInput
        name={name}
        id={id}
        type="checkbox"
        checked={check ? 1 : 0}
        innerRef={ref}
        onClick={() => setCheck(!check)}
        onBlur={onBlur}
        onChange={ (event) => {
          onChange(event);
          handleChange({ name: name, value: ( event.target.checked ? 1 : 0 ) });
        }}
        label={
          <Fragment>
            {label}
            { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
          </Fragment>
        }
        {...rest}
      />
    </FormGroup>
  );

};

export default FormInputCheckbox;
