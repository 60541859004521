import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import AppContext from '../context/Context';
// import { getPageName } from '../helpers/utils';
import AppRouter from "../components/AppRouter";


const WorkLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = false; // getPageName('kanban');
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical navbarStyle={navbarStyle} />}
        <div className="content">
          <NavbarTop />
          <AppRouter/>
          <Footer />
        </div>
    </div>
  );
};

WorkLayout.propTypes = { location: PropTypes.object.isRequired };

export default WorkLayout;