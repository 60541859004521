import React from 'react';
import { useListItem } from "hooks/useListItem";
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListItem = ({namespace, itemId, field="title"}) => {
  const {getItem} = useListItem(namespace);
  const item = getItem(itemId);

  if(item.badge_color || item.icon) {
    const color = item.badge_color || 'soft-light';

    return (
      <>
        { item && <Badge color={color} key={item.id} className="fs--1 rounded-capsule">
          { item.icon && <FontAwesomeIcon icon={item.icon} transform="shrink-2" className="fs--1 ml-1" />}
          {item[field]}
        </Badge> }
      </>
    );

  }

  else {

    return (
      <>
        {item && item[field]}
      </>
    );
  }
};

export default ListItem;