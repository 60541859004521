import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {baseObjectInitial, baseObjectReducers, baseObjectExtraReducers} from "./shared/reducerBaseObject";
import axios from "axios";
import AuthService from "services/AuthService";
import {API_URL} from "http/index";


const initialState = {
  ...baseObjectInitial
};

const namespace = 'auth';

const AuthActionCreators = {
    check: createAsyncThunk('auth/check', async (_, thunkAPI) => {
      console.log('check auth slice');
        try {
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }),
    register: createAsyncThunk('auth/register', async (item={}, thunkAPI) => {
        try {
            const response = await AuthService.register(item);
            return response.data;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    login: createAsyncThunk('auth/login', async ({ email, password}, thunkAPI) => {
        try {
            const response = await AuthService.login(email, password);
            return response.data;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    loginAs: createAsyncThunk('auth/loginAs', async ({ userId }, thunkAPI) => {
        try {
            const response = await AuthService.loginAs(userId);
            return response.data;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    logout: createAsyncThunk('auth/logout', async (_, thunkAPI) => {
        try {
            await AuthService.logout();
            return true;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    }),
    logoutToParent: createAsyncThunk('auth/logoutToParent', async (_, thunkAPI) => {
        try {
            const response = await AuthService.logoutToParent();
            return response.data;
        } catch(e) {
          return thunkAPI.rejectWithValue(e)
        }
    })
}

const authSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    ...baseObjectReducers

  },
  extraReducers: builder => {
      builder
      .addCase(AuthActionCreators.check.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.accessToken);
        state.item = action.payload.user
      })
      .addCase(AuthActionCreators.login.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.accessToken);
        state.item = action.payload.user
      })
      .addCase(AuthActionCreators.loginAs.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.accessToken);
        state.item = action.payload.user
      })
      .addCase(AuthActionCreators.register.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.accessToken);
        state.item = action.payload.user
      })
      .addCase(AuthActionCreators.logout.fulfilled, (state, action) => {
        localStorage.removeItem('token');
        state.item = {}
      })
      .addCase(AuthActionCreators.logoutToParent.fulfilled, (state, action) => {
          localStorage.setItem('token', action.payload.accessToken);
          state.item = action.payload.user
      })
      baseObjectExtraReducers(builder, namespace);
  }
});



export const isAuthSelector = (state) => (state.auth.item?.id ? true : false )

export const actions = {
  ...authSlice.actions,
  ...AuthActionCreators
};


export default authSlice.reducer