import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../router";
import {useSelector} from "react-redux";
import {RouteNames} from "router/routes-names";
import {isAuthSelector} from 'store/reducers/auth'

const AppRouter = () => {

    const isAuth = useSelector(isAuthSelector);
    return (
        isAuth ?
        <Switch>
            {privateRoutes.map(route =>
                <Route
                    component={route.component}
                    path={route.path}
                    exact={route.exact}
                    key={route.path}
                />
            )}
            <Redirect to={RouteNames.ORDERS} />
        </Switch>
        :
        <Switch>
            {publicRoutes.map(route =>
                <Route
                    component={route.component}
                    path={route.path}
                    exact={route.exact}
                    key={route.path}
                />
            )}
            <Redirect to={RouteNames.LOGIN} />
        </Switch>
    );
};

export default AppRouter;