import React, {useEffect, useState} from 'react';
import { FormGroup, Spinner } from 'reactstrap';
import classNames from 'classnames';
import {isIterableArray} from 'helpers/utils';
import CreatableSelect from 'react-select/creatable';
import {useObjectActions} from "hooks/useActions";
import {useSelector} from "react-redux";
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

// Новый вариант создается сразу в namespace с помощью метода add
const FormInputSelectCreatable = ({
  label,
  id,
  name,
  className,
  placeholder,
  tooltip,
  options = [], // варианты для select
  isLoading, // признак загрузки списка в случае с select
  handleChange,
  namespace, // имя редюсера, отвечающего за список, в который добавляется запись
  value='',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;

  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedOption, setSelectedOption] = useState();
  const {add} = useObjectActions(namespace);
  const {item, isAdded} = useSelector(state => state[namespace]);

  // обновление текущего в списке
  useEffect(() => {
    if(selectedValue && !isLoading && isIterableArray(options)) {
        const found = options.filter(option => selectedValue === option["id"])
        if(found[0]) {
          setSelectedOption({ value: found[0]["id"], label: found[0]["title"]});
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, isLoading, isIterableArray(options)]);

  // после добавления нового значения в список вызываем метод изменения значения списка и передаем ему новое значение
  useEffect(() => {
    if(item.id) {
      handleChange({ value: item.id, name: name });
      formMethods.setValue(name, item.id, { shouldDirty: true });
      setSelectedValue(item.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded]);


  // установка начального значения в react-hook-form
  useEffect(() => {
      setSelectedValue(value);
      formMethods.setValue(name, value, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  const { ref, onBlur } = formMethods.register(name);

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 'normal',
      color: '#b6c1d2',
      fontSize: '.875rem',
    }),
    input:(provided, state) => ({
      ...provided,
      cursor: 'text',
    }),
  }
  return (
    <>
      {  ( isLoading || !isIterableArray(options) ) ?
         (  <Spinner size="sm" color="primary" style={{marginLeft: 10}}/> ) :
         (
            <FormGroup>
              <InputLabel id={id} label={label} tooltip={tooltip}/>
              <CreatableSelect
                isClearable
                name={name}
                isLoading={isLoading}
                id={id}
                placeholder={placeholder}
                value={selectedOption}
                options={ options.map( row => ( { value: row["id"], label: row["title"]} ) ) }
                className={classNames(className, { 'border-danger': errors[name]?.message })}
                formatCreateLabel={(inputText) => `Добавить вариант "${inputText}"`}
                innerRef={ref}
                onBlur={onBlur}
                styles={customStyles}
                onChange={(newValue, actionMeta) => {
                    console.log(newValue, actionMeta);
                    let value = 0;
                    if(newValue?.__isNew__) {
                      value = '-1';
                      if(namespace) {
                        add({ title: newValue.label });
                      }
                    } else if(newValue?.value) {
                      value = newValue.value;
                      setSelectedValue(value);
                      // чтобы обновлялись данные в react-hook-form
                      formMethods.setValue(name, value, { shouldDirty: true });
                      handleChange({ value: value, name: name });
                    }
                    if(actionMeta.action === 'clear') {
                      setSelectedOption(undefined);
                      // чтобы обновлялись данные в react-hook-form
                      formMethods.setValue(name, 0, { shouldDirty: true });
                      handleChange({ value: 0, name: name });
                    }
                }}
                {...rest}
              />
              { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
            </FormGroup>
        )
    }
  </>
  );

};

export default FormInputSelectCreatable;
