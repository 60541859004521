import React from 'react';
import {Card} from 'reactstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {useActions} from "hooks/useActions";
import orderBy from 'lodash/orderBy';
import SPEditorByRow from 'components/editor/SPEditorByRow';

const Brokers = () => {
  const {brokersGetList, brokersSetList, brokersAdd, brokersRemove, brokersEdit } = useActions()

  const editorHeader = "Брокеры";

  const inputs = [
    {
      header: "ID",
      type: "text",
      name: "id",
      disabledEdit: true,
    },
    {
      header: "Название",
      type: "text",
      name: "title",
      required: {
        required: 'Введите название'
      },
    },
    {
      header: "В черном списке",
      type: "checkbox",
      name: "is_blacklisted",
      colProps: {"align": "center"},
      classes: 'align-top',
    },
  ];

  const sort = (list) => orderBy(list, ['title']);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={editorHeader} light={false} />
        <SPEditorByRow
          getList={brokersGetList}
          setList={brokersSetList}
          add={brokersAdd}
          remove={brokersRemove}
          edit={brokersEdit}
          sort={sort}
          namespace='brokers'
          colsInputs={inputs}
        />
    </Card>
  );
};

export default Brokers;







