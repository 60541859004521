import axios from 'axios';
import {store} from "store";
import {SystemActionCreators} from 'store/reducers/system/action-creators';
// import {AxiosRequestConfig} from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL);
const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL
})

$api.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  return config;
})

$api.interceptors.response.use((config) => { return config; }, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
      localStorage.setItem('token', response.data.accessToken);
      return $api.request(originalRequest);
    } catch (e) {
      console.log('НЕ АВТОРИЗОВАН')
    }
  } else if(error.response.status === 500 && error.response && error.response.data && error.response.data.errors) {
    // пока кладу сюда одну ошибку, так как в цикле ошибок может быть много, и будет очень много текста
    store.dispatch(SystemActionCreators.setSystemError('Ошибка', error.response.data.errors[0].message));
    console.log(error.response);
  } else {
    console.log(error.response?.data?.message);
  }
  throw error;
})

export default $api;
