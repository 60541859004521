import {useEffect} from 'react';
import {useActions} from "hooks/useActions";
import {useOrderStatuses} from "hooks/useOrderStatuses";
import {useSelector} from "react-redux";

export const useOrders = () => {
  const {getDeletedStatus} = useOrderStatuses();
  const {ordersEdit, ordersSetIsUpdated} = useActions();
  const {isUpdated} = useSelector(state => state.orders);

  // удаление заказа - это обновление статуса, поэтому при удалении остается отмеченным флаг isUpdated
  // поэтому при удалении убираем его обратно
  useEffect(() => {
    if(isUpdated) {
      ordersSetIsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  // удаление заказа -> смена статуса на Удален
  const deleteOrder = (itemIds) => {
    const deletedStatusId =  getDeletedStatus().id;
    itemIds.forEach( itemId => ordersEdit([itemId, { order_status_id: deletedStatusId} ]) )
  }


  return {deleteOrder};
}