import React, {useEffect, useState} from 'react';
import { FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import InputLabel from './InputLabel';

const FormInputFile = ({
  label,
  id,
  name,
  placeholder,
  tooltip,
  className,
  handleChange,
  value='',
  type='file',
  ...rest
}) => {
  const formMethods = useFormContext();
  const {errors} = formMethods.formState;
  const {ref, onBlur} = formMethods.register(name);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    // сбрасываем значение, если оно обнуляется
    if(!value) {
      setCurrentValue(value);
      formMethods.setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormGroup>
      <InputLabel id={id} label={label} tooltip={tooltip}/>
      <Input
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
        className={classNames(className, { 'border-danger': errors[name]?.message })}
        innerRef={ref}
        onBlur={onBlur}
        {...rest}
        // onchange должнен быть после rest, на случай, если там он определен
        onChange={ (event) => {
          // onChange(event);
          // console.log(event);
          setCurrentValue(event.target.value);
          formMethods.setValue(name, event.target.files[0] );

          handleChange({ name: name, value: event.target.files[0] });
        }}
        value={currentValue}
      />

      { errors[name] && <span className="fs--1 font-weight-normal text-danger d-inline-block">{errors[name].message}</span> }
    </FormGroup>
  );
};

export default FormInputFile;



//
//  <Controller
//         name={name}
//         control={control}
//         defaultValue={defaultValue}
//         type="text"
//               autoFocus={true}
//         render={({ field: { onChange, onBlur, value, ref } }) => {
//           return (
//             <Input
//               placeholder={placeholder}
//               value={value}
//               onBlur={onBlur}
//               inputRef={ref}
//               className={classNames(className, { 'border-danger': errors[name]?.message })}
//               onChange={ (event) => {
//                 onChange(event);
//                 handleChange({ name: name, value: event.target.value });
//               }}
//             />
//           )
//         }
//       }
//       />