import React from 'react';
import TableEditor from "components/editor/TableEditor";
import {VehicleColumns} from "./components/VehicleColumns";
import {RouteNames} from "router/routes-names";
import {useHistory} from 'react-router-dom';
import {useActions} from "hooks/useActions";
import {useSelector} from "react-redux";


const Vehicles = () => {
  const namespace="vehicles";
  const router = useHistory()
  const {vehiclesRemove} = useActions()
  const {error} = useSelector(state => state.vehicles);

  const withSelectedOptions = [
    {value: "delete", title: "Удалить", callback: vehiclesRemove },
  ];

  const actionButtons = [
      {icon: "plus", title: "Добавить", link: RouteNames.VEHICLES_ADD}
  ];
  // список действий над выбранными элементами
  const itemActionsMenu = (row) => [
    {title: "Редактировать", callback: (row) => router.push(RouteNames.VEHICLES_EDIT(row.id)) },
    {title: "Удалить", callback: async (owner) => vehiclesRemove([owner.id]), className: "text-danger" }
  ]

  return (
    <TableEditor
      title="Транспортные средства"
      columns={VehicleColumns(itemActionsMenu)}
      withSelectedOptions={withSelectedOptions}
      actionButtons={actionButtons}
      error={error}
      namespace={namespace}
    />
  );
};

export default Vehicles;